.range-chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .range-chart-wrapper {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    .rcw-top {
      width: 100%;
      height: 20%;
      display: flex;
      .rcw-top-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        h3 {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #7a7a7a;
        }
        div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: 100%;
          h3 {
            margin: 0;
            font-weight: 600;
            font-size: 20px;
            line-height: 27.24px;
            color: #272828;
          }
          div {
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
            }
            p {
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              color: #23b04a;
            }
          }
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #7a7a7a;
          }
        }
      }
      .rcw-top-right {
        flex: 1;
      }
    }
    .rcw-bottom {
      width: 100%;
      height: 80%;
      .apexcharts-canvas{
        .apexcharts-toolbar{
          z-index: 0 !important;
        }
      }
    }
  }
}
