.user-profile-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .user-profiile-wrapper {
    width: 90%;
    height: 93%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: white;
    .user-profile-top {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      .up-top-left-container {
        // width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-direction: column-reverse;
        // .user-name {
        //   width: 150px;
        //   height: 100%;
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: center;
        //   align-items: flex-start;
        //   gap: 5px;
        //   h3 {
        //     margin: 0;
        //   }
        //   p {
        //     margin: 0;
        //   }
        // }
        .pp-dp {
          height: 100%;
          width: 110px;
          border-radius: 50%;
          object-fit: cover;
        }
        .upload-dp {
          border: 1px solid #2d5fa1;
          outline: none;
          width: 90px;
          height: 28px;
          border-radius: 8px;
          background-color: white;
          color: #2d5fa1;
          font-weight: 400;
          font-size: 12px;
          line-height: 19.36px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          .download-icon {
            width: 10px;
          }
        }
      }
      .up-top-right-container {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn-edit {
          width: 100px;
          height: 48px;
          cursor: pointer;
          background-color: #00468a;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #fcfcfc;
          border-radius: 8px;
          border: none;
          outline: none;
        }
      }
    }
    .user-profile-bottom {
      width: 65%;
      height: 50%;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .user-profile-details {
        width: 300px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40%;
          margin: 0;
        }
        label {
          width: 100%;
          height: 60%;
          border-radius: 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: 1px solid lightgray;

          input {
            width: 90%;
            height: 70%;
            border: none;
            outline: none;
            color: #4c4848;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            padding-left: 8px;
            color: #b3b3b3;
          }
          input:disabled {
            background-color: white;
            color: #111;
            font-weight: 400;
            padding-left: 8px;
          }
        }
      }
    }
    .button-container {
      width: 80%;
      height: 70px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      .reset-btn { 
        width: 100px;
        height: 48px;
        cursor: pointer;
        background-color: white;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #00468a;
        border-radius: 8px;
        border: none;
        outline: none;
        border: 1px solid #00468a;
      }
      .save-btn {
        width: 100px;
        height: 48px;
        cursor: pointer;
        background-color: #00468a;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fcfcfc;
        border-radius: 8px;
        border: none;
        outline: none;
      }
    }
  }
}

.file-upload-input {
}
