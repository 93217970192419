.sidebar-wrapper {
  padding-top: 12px;
  background-color: #00468a;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: fixed;
  width: 205px;
  height: 100vh;
  overflow: visible;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    display: none;
    width: 0;
    height: 0;
    background: transparent;
  }
  
  .sidebar-item {
    width: 200px;
    padding: 8px 2px;
    display: flex;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    .sidebar-left {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
       img {
          &[src*="Dashboard"] {
              opacity: 0.8;
              filter: brightness(1.2);
              background: transparent;
            }
      }
      img {
          &[src*="jobsProfile"] {
              opacity: 0.8;
              filter: brightness(1.2);
              background: transparent;
            }
      }
      img {
          &[src*="newUser"] {
              // opacity: 0.8;
              // filter: brightness(1.2);
              background: transparent;
            }
      }
       img {
          &[src*="stickynote"] {
              opacity: 0.8;
              filter: brightness(1.2);
              background: transparent;
            }
      }
      label {
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.07px;
        color: #ffffff;
        margin-left: 5px;
      }
    }
    .sidebar-right {
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .active {
    background-color: #ffffff;

    border-radius: 8px;
    .sidebar-left {
      img {
        background-color: #00468a;
        border-radius: 10%;
      }
      label {
        color: #f04835;
        text-decoration: none;
      }
    }
  }

  // First circular overlay
  &::before {
    content: '';
    position: fixed;
    width: 400px;
    height: 400px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 50%;
    bottom: -200px;
    left: 50px;
    z-index: 0;
    pointer-events: none;
  }

  // Second circular overlay
  &::after {
    content: '';
    position: fixed;
    width: 300px;
    height: 300px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 50%;
    top: 40%;
    left: -150px;
    z-index: 0;
    pointer-events: none;
  }
}

* {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    display: none;
    width: 0;
    height: 0;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media only screen and (max-width: 480px) {
  .hamburger-menu {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 24px;
    background: transparent;
    border: none;
    padding: 0;
    box-sizing: border-box;
    position: absolute;
    top: 12px; /* Adjust vertical position */
    left: 12px; /* Adjust horizontal position */
  }
  
  .bar {
    width: 30px;
    height: 4px;
    background: #ffffff;
  }

  .menu-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 255, 0.5); /* Blue background with transparency */
    z-index: 999; /* Ensure the menu is above other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .menu-items {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  }
  
  .menu-item {
    color: black; /* Change menu item color to black */
    padding: 10px;
    cursor: pointer;
  }
  
  .menu-item:hover {
    background-color: #f0f0f0; /* Change background color on hover */
  }
  
  
}




a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}
.sidebar-item-li {
  width: 195px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 6px;
  padding-top: 8px;
  position: relative;
  z-index: 1;
  li {
    margin-left: 35px;
    width: 149px;
    height: 28px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.07px;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding-left: 5px;
    display: flex;
    align-items: center;
    gap: 70px;
    cursor: pointer;
  }
  .li-active {
    color: #00468a;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.07px;
    background-color: #ffffff;
    border-radius: 8px;
  }

  .dropdownStick-one {
    position: absolute;
    top: -15px;
    left: 20px;
  }
  .dropdownStick-two {
    position: absolute;
    top: 17px;
    left: 20px;
  }
  .dropdownStick-three {
    position: absolute;
    top: 50px;
    left: 20px;
  }
  .dropdownStick-four {
    position: absolute;
    top: 85px;
    left: 20px;
  }
  .dropdownStick-five {
    position: absolute;
    top: 120px;
    left: 20px;
  }
  .dropdownStick-six {
    position: absolute;
    top: 153px;
    left: 20px;
  }
  .dropdownStick-seven {
    position: absolute;
    top: 187px;
    left: 20px;
  }
}

.sidebar-report-item-li {
  position: relative;
  width: 195px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  gap: 6px;
  padding-top: 8px;

  li {
    margin-left: 50px;
    width: 135px;
    height: 28px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.07px;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding-left: 5px;
  }
  .li-active {
    color: #00468a;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.07px;
    background-color: #ffffff;
    border-radius: 8px;
  }
  .dropdownStick-report-one {
    position: absolute;
    top: -20px;
    left: 35px;
  }
  .dropdownStick-report-two {
    position: absolute;
    top: 15px;
    left: 35px;
  }
  .dropdownStick-report-three {
    position: absolute;
    top: 50px;
    left: 35px;
  }
  .dropdownStick-report-four {
    position: absolute;
    top: 85px;
    left: 35px;
  }
  .dropdownStick-report-five {
    position: absolute;
    top: 120px;
    left: 35px;
  }
}

.circle-overlay {
  &::before {
    content: '';
    position: fixed;
    width: 250px;
    height: 250px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 50%;
    top: 20%;
    left: -125px;
    z-index: 0;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: fixed;
    width: 200px;
    height: 200px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 50%;
    bottom: 30%;
    left: -100px;
    z-index: 0;
    pointer-events: none;
  }
}

@media screen and (max-width: 1024px) {
  .mobile-menu-btn {
    display: block;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1100;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    
    img {
      width: 28px;
      height: 28px;
    }
  }

  .sidebar-overlay.show {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .sidebar-wrapper.mobile-open {
    transform: translateX(0);
    position: fixed;
    z-index: 1000;
  }

  .sidebar-wrapper {
    transform: translateX(-100%);
    position: fixed;
    transition: transform 0.3s ease;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .sidebar-wrapper.mobile-open {
    width: 300px;
    
  }
}

@media screen and (min-width: 1025px) {
  .mobile-menu-btn {
    display: none;
  }
  
  .sidebar-overlay {
    display: none;
  }
  
  .sidebar-wrapper {
    transform: none !important;
    position: relative !important;
  }
}
