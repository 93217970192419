.button-yes-interview {
  width: 163.47px;
  height: 45.11px;
  border-radius: 8px;
  background-color: #00468a;
  color: #fcfcfc;
  font-weight: 600;
  line-height: 24.85px;
  font-size: 18.06px;
  border: 1px solid #fcfcfc;
  outline: none;
  cursor: pointer;
}