.OkButton {
  padding: 0 10px;
    width: auto;
    height: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    background-color: #d74f58;
    border-radius: 4px;
    outline: none;
    border: none;
    color: white;
    cursor: pointer;
}