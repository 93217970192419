.profile-card-container {
  width: 90%;
  height: 60px !important;
  margin-bottom: 10px;
  margin-left: 20px;
  border-radius: 8px;
  position: relative;
  // border: 1px solid #b3b3b3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .profile-card-wrapper {
    width: 95%;
    height: 85%;
    display: flex;
    // .user-photo-profile {
    //   width: 45px;
    //   height: 45px;
    //   img {
    //     height: 100%;
    //     width: 100%;
    //     object-fit: cover;
    //     border-radius: 50%;
    //   }
    // }

    .user-photo-profile {
      width: 20%;
      position: relative;
      .dp-badge{
        position: absolute;
        z-index: 3;
        width: 35%;
        height: 35%;
        border: 0;
        top: 40px;
        left: 22%;
        aspect-ratio: inherit;
        overflow: hidden;
        image-rendering: pixelated;
      }
      .badge-border {
        border: 2px solid gold;
      }
      img {
        border-radius: 50%;
        width: 75%;
        height: 90%;
        object-fit: cover;
        position: absolute;
        border: 2px solid transparent;
        overflow: hidden;
        z-index: 2;
        // z-index: -1;
      }
      .dp-badge-hover::before{
        content: attr(data-message);
        position: absolute;
        bottom: -15px;
        left: 40px;
        width: 160px;
        padding: 2px;
        background-color: rgba(255, 217, 0, 0.781);
        color: black;
        font-size: 12px;
        display: none;
        text-align: center;
        border-radius: 10px;
        box-shadow:  0 0 2px gold;
        //font-weight: bold;
      }
      
      .dp-badge-hover:hover::before{
        display: block;
      }
    }

    .user-details {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      .ud-one {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        h4 {
          margin: 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 28px;
          color: #262626;
        }
        .dp-tickmark{
          width: 20px;
          height: 20px;
          padding-left: 3px;
          // bottom: 10px
           //margin-top: 10px;
         }
      }
      .ud-two {
        flex: 2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          margin: 0;
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          color: #808080;
        }
      }
    }

    .user-experience {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        color: #808080;
      }
    }
  }
  &.active {
    background-color: #173451;
    .profile-card-wrapper {
      .user-details {
        .ud-one {
          h4 {
            color: white;
          }
        }
        .ud-two {
          p {
            color: white;
          }
        }
      }
      .user-experience {
        p {
          color: white;
        }
      }
    }
  }
  .banner-tag {
    position: absolute;
    top: 0px;
    right: 20px;
    img {
      width: 10px;
    }
  }

  // flag tape start
  .flag-tape {
    // background: linear-gradient(to right, blue, red);
    // background: navy;
    background: #FFBF00;
    color: white;
    font-weight: bold;
    font-size: 10px;
    padding: 3px 1px;
    border-radius: 3px;
    position: absolute;
    top: -2px;
    left: 85%;
    transform: translateX(-50%);
    width: 103px; /* Adjusted */
    // min-width: 150px; 
    display: none;
  }

  .flag-tape::before {
    content: "";
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    transform: translateY(-50%);
  }

  .flag-tape::before {
    left: -9px;
    // top: 11.5px;
    border-width: 10px 10px 10px 0;
    // border-color: transparent navy transparent transparent;
    border-color: transparent #FFBF00 transparent transparent;
  }
// flag tap end
.flag-tape-visible {
  display: block; /* Show when the status is BGVTRIGGERED */
}

//start color for amber
  .flag-tape-amber {
    background: #FFBF00; /* Amber color */
}

.flag-tape-amber::before {
    border-color: transparent #FFBF00 transparent transparent;
}

//start red color
.flag-tape-red {
    background: red;
}

.flag-tape-red::before {
    border-color: transparent red transparent transparent;
}
}
