.profile-details-wrapper {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: white;
  .profile-details-container {
    width: 90%;
    height: 95%;
    display: flex;
    flex-direction: column;
    .pd-one {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding-bottom: 20px;
      .close-profile {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        img {
          cursor: pointer;
        }
      }
      .user-profile-img {
        width: 130px;
        height: 130px;
        // border-radius: 50%;
        // background-color: lightgrey;
        margin-bottom: 30px;
        position: relative;
        .dp-badge{
          position: absolute;
          z-index: 3;
          width: 35%;
          height: 35%;
          border: 0;
          top: 116px;
          left: 36%;
          aspect-ratio: inherit;
          overflow: hidden;
          image-rendering: pixelated;
        }
        .badge-border {
          border: 5px solid gold;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          position: absolute;
          border: 5px solid transparent;
          overflow: hidden;
          z-index: 2;
        }
        .dp-badge-hover::before{
          content: attr(data-message);
          position: absolute;
          bottom: -25px;
          left: 110px;
          width: 160px;
          padding: 5px;
          background-color: rgba(255, 217, 0, 0.781);
          color: black;
          font-size: 12px;
          display: none;
          text-align: center;
          border-radius: 10px;
          box-shadow:  0 0 2px gold;
          //font-weight: bold;
        }
        
        .dp-badge-hover:hover::before{
          display: block;
        }
        // img {
        //   border-radius: 50%;
        //   width: 82%;
        //   height: 109%;
        //   object-fit: cover;
        //   position: absolute;
        //   border: 5px solid transparent;
        //   overflow: hidden;
        //   z-index: 2;
        //   // z-index: -1;
        // }
      }
      .user-profile-invite {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .up-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
          p {
            font-weight: 500;
            font-size: 24px;
            line-height: 18px;
            color: #4d4d4d;
            margin: 0;
          }
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #4d4d4d;
          }
        }
        .up-bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
          img {
            width: 20px;
          }
          button {
            width: 191px;
            height: 38px;
            color: #d74f58;
            border: 1px solid #d74f58;
            font-weight: 500;
            font-size: 16px;
            line-height: 21.79px;
            border-radius: 8px;
            background-color: white;
            cursor: pointer;
          }
          .disabled-button {
            width: 191px;
            height: 38px;
            color: white;
            border: 1px solid #02B17B;
            font-weight: 500;
            font-size: 16px;
            line-height: 21.79px;
            border-radius: 8px;
            background-color: #02B17B;
          }
        }
      }
    }
    .pd-two {
      flex: 1;
      display: flex;
      .pd-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #808080;
          margin: 0;
        }
      }
      .pd-right {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-end;
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #262626;
          margin: 0;
        }
        .email {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #117fd6;
        }
      }
    }
    .pd-three {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          display: flex;
          gap: 10px;
          img {
          }
          div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .file-name {
              margin: 0;
              font-weight: 500;
              font-size: 16px;
              line-height: 18px;
              color: #808080;
            }
            .file-size {
              margin: 0;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #808080;
            }
          }
        }
        img {
        }
      }
    }
    .flag-tape {
      background: navy;
      color: white;
      font-weight: bold;
      font-size: 11px;
      text-align: center;
      padding: 3px 11px;
      border-radius: 3px;
      position: absolute;
      top: 1px;
      left: 11%; /* Adjusted from right to left */
      transform: translateX(-50%);
      width: 80px; /* Adjusted */
      // min-width: 90px; /* Adjusted for minimum width */
    }
    
    .flag-tape::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      transform: translateY(-50%);
    }
    
    .flag-tape::after {
      right: -9px;
      border-width: 10px 0 10px 10px;
      border-color: transparent transparent transparent navy;
    }
    
  }
}

.hr-line {
  width: 100%;
  border: 1px solid #e6e6e6;
}
