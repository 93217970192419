.profile-wrapper {
  height: 227px;
  padding: 20px 0;
  width: 188px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  box-shadow: -2px 3px 9px -1px rgba(0, 0, 0, 0.75);
  .profile-container-top {
    width: 150px;
    // height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    position: relative;
    
    .dp-badge{
      position: absolute;
      z-index: 3;
      width: 30%;
      height: 30%;
      border: 0;
      top: 66px;
      left: 35%;
      aspect-ratio: inherit;
      overflow: hidden;
      image-rendering: pixelated;
    }
    .badge-border {
      border: 4px solid gold;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      object-fit: cover;
      // position: absolute;
      overflow: hidden;
      z-index: 2;
      margin-bottom: 30px;
    }
    
    .profile-container-top-img {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      object-fit: cover;
      // position: absolute;
      border: 5px solid transparent;
      overflow: hidden;
      z-index: 2;
    }
    .dp-badge-hover::before{
      content: attr(data-message);
      position: absolute;
      bottom: 65px;
      left: 105px;
      width: 160px;
      padding: 4px;
      background-color: rgba(255, 217, 0, 0.781);
      color: black;
      font-size: 12px;
      display: none;
      text-align: center;
      border-radius: 10px;
      box-shadow:  0 0 2px gold;
      //font-weight: bold;
    }
    
    .dp-badge-hover:hover::before{
      display: block;
    }
    h3 {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #4d4d4d;
      text-align: center;
    }
    h5 {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #4d4d4d;
      text-align: center;
    }
    h5 {
      background: linear-gradient(to right, blue, red);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .flag-tape {
      // background: linear-gradient(to right, blue, red);
      background: navy;
      color: white;
      font-weight: bold;
      font-size: 11px;
      padding: 3px 1px;
      border-radius: 3px;
      position: absolute;
      top: -20px;
      left: 80%;
      transform: translateX(-50%);
      // width: 100px; /* Adjusted */
      // min-width: 150px; 
    }

    .flag-tape::before {
      content: "";
      position: absolute;
      top: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      transform: translateY(-50%);
    }

    .flag-tape::before {
      left: -9px;
      // top: 11.5px;
      border-width: 10px 10px 10px 0;
      border-color: transparent navy transparent transparent;
    }

    // .flag-tape::after {
    //   right: -10px;
    //   border-width: 10px 0 10px 10px;
    //   border-color: transparent transparent transparent red;
    // }
    div {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100px;
      img {
      }
      p {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.07px;
        color: #808080;
      }
    }
  }
  .profile-container-bottom {
    width: 120px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .user-exp {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h3 {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 14.4px;
        color: #808080;
      }
      p {
        margin: 0;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #808080;
      }
    }
    .ratting {
      img {
      }
    }
    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      color: #b3b3b3;
    }
  }
}
