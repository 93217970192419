.job-match-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 797px;
  height: 541px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  .job-match-card-wrapper {
    width: 80%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .job-match-card-top {
      height: 20%;
      width: 100%;
      display: flex;
      flex-direction: column;
      .successful-top {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        img {
        }
        p {
          color: #2d5fa1;
          font-weight: 600;
          font-size: 20px;
          line-height: 27.24px;
          margin-bottom: 20px;
        }
      }
      .successful-bottom {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          color: #d74f58;
          font-weight: 500;
          font-size: 16px;
          line-height: 21.79px;
        }
      }
    }
    .job-match-card-center {
      height: 60%;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .user-info {
        width: 141px;
        height: 230px;
        background-color: #2d5fa1;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .user-info-top {
          width: 80%;
          height: 60%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          position: relative;
         

          .dp-badge{
            position: absolute;
            z-index: 3;
            width: 38%;
            height: 32%;
            border: 0;
            top: 64px;
            left: 30%;
            aspect-ratio: inherit;
            overflow: hidden;
            image-rendering: pixelated;
          }
          .badge-border {
            border: 4px solid gold;
            margin-bottom: 25px;
          }
          
          
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            object-fit: cover;
            z-index: 2;
            //margin-bottom: 25px;
          }
          .dp-badge-hover::before{
            content: attr(data-message);
            position: absolute;
            bottom: 35px;
            left: 80px;
            width: 160px;
            padding: 3px;
            background-color: rgba(255, 217, 0, 0.781);
            color: black;
            font-size: 12px;
            display: none;
            text-align: center;
            border-radius: 10px;
            box-shadow:  0 0 2px gold;
            //font-weight: bold;
          }
          
          .dp-badge-hover:hover::before{
            display: block;
          }
          .user-profile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h3 {
              margin: 0;
              padding: 0;
              color: #ffffff;
              font-weight: 600;
              font-size: 14px;
              line-height: 14px;
            }
            p {
              margin: 0;
              padding: 0;
              color: #ffffff;
              font-weight: 400;
              font-size: 12px;
              line-height: 14.4px;
            }
          }
          span {
            margin: 0;
            padding: 0;
            color: #ffffff;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
          }
          
        }
        .user-info-bottom {
          width: 65%;
          height: 20%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .user-ratting {
          }
          span {
            color: #ffffff;
            font-size: 10px;
            font-weight: 400;
            line-height: 10px;
          }
        }
      }
    }
    .job-match-card-bottom {
      height: 20%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        border-radius: 8px;
        width: 144px;
        height: 46px;
        color: #2d5fa1;
        background-color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        line-height: 21.79px;
        border: 1px solid #2d5fa1;
        cursor: pointer;
      }
    }
  }
}
