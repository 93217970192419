.interview-slot-container {
  position: absolute;
  left: 15%;
  top: 10%;
  background-color: white;
  width: 71%;
  height: 65vh;
  border-radius: 8px;

  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  display: flex;
  justify-content: center;
  align-items: center;
  .is-wrapper {
    width: 830px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .heading-container {
      height: 20%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-weight: 400;
        font-size: 20px;
        line-height: 27.24px;
        color: black;
      }
      p {
      }
      img {
      }
    }
    .input-container {
      height: 60%;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .interview-date-style {
        color: black !important;
        font-size: 16px !important;
        line-height: 18px !important;
        font-weight: 400 !important;
      }
      div {
        position: relative;
        // width: 265px;
        // height: 100%;
        .input-container {
          border-radius: 8px;
          display: flex;
          width: 90%;
          border: 1px solid #e9e9e980;
          height: 48px;
          justify-content: space-around;
          align-items: center;
          margin-block-end: 10px;
          input {
            width: 80%;
            height: 70%;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #9a9a9a;
            border: none;
            outline: none;
          }
          img {
            cursor: pointer;
          }
        }
        .calendar-date-input {
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
        }
        h3 {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #262626;
        }
      }
    }
    .slot-button {
      width: 92%;
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.dropdown-hospital-locum-job {
  width: 310px;
}

.interview-slot-container
  .is-wrapper
  .input-container
  div
  .input-container
  input {
  color: black;
}
