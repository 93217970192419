.btn-Completed {
  width: 90px;
  height: 27px;
  background-color: #ccefe5;
  color: #02b17b;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.07px;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
}
