.dashboard-wrapper {
  background-color: #f5f5f5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  min-width: 1440px;

  .dashboard-container {
    height: 93%;
    width: 97.5%;
    margin: 20px 0px 20px 20px;
    background-color: #f5f5f5;
    max-width: 100%;

    .two {
      margin-top: 20px;
      width: 100%;
      height: 130px;
      background-color: #f5f5f5;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }

    .tables-section {
      width: 101%;
      margin-top: 40px;
      min-width: 1440px;

      .content-container {
        display: flex;
        gap: 15px;
        margin: 24px 0;
        min-width: 0;

        .section {
          background: #fff;
          border-radius: 8px;
          padding: 20px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
          min-width: 700px;

          h3 {
            color: #666;
            font-size: 16px;
            font-weight: 600;
            margin-top: 5px;
          }
        }

        .chart-section {
          flex: 0 0 60%;
          min-width: 0;

          .chart-area {
            width: 100%;
            height: 350px;
            padding: 20px;

            canvas {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }

        .pie-charts-section {
          flex: 0 0 40%;
          min-width: 0;

          .chart-container {
            background: #fff;
            border-radius: 8px;
            margin-top: -2px;
            padding: 20px;
            height: 450px;
            // width: 565px;
            width: 83%;
            margin-left: 5px;

            .pie-chart {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .table-section {
        width: 96.8%;
        margin-top: 24px;
        background: #fff;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
        overflow-x: hidden;
        


        h3 {
          color: #666;
          font-size: 16px;
          font-weight: 600;
        }

        table {
          width: 100%;
          border-collapse: collapse;

          th,
          td {
            padding: 12px;
            text-align: left;
            border-bottom: 1px solid #e5e7eb;
          }

          th {
            font-weight: 600;
            color: #6b7280;
            background: #f9fafb;
            font-size: 14px;
          }

          tr:nth-child(even) {
            background-color: #f7fbfe;
        }

          td {
            // background-color: blue  ;
            color: #111827;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.specialization-charts-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
  margin-top: 20px;
  min-width: 1440px;

  .chart-container {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    min-width: 700px;

    .chart-title {
      color: #666;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 30px;
      text-transform: uppercase;
    }

    .chart-content {
      display: flex;
      align-items: center;
      gap: 20px;

      .pie-chart {
        width: 60%;
        height: 380px;
        position: relative;
        margin-bottom: 50px;

        canvas {
          max-width: 100%;
          height: auto !important;
        }
      }

      .legend-container {
        width: 45%;

        .legend-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          gap: 30px;
          margin-left: 40px;

          .legend-label {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 13px;
            color: #333;
            min-width: 120px;

            .dot {
              width: 7px;
              height: 7px;
              border-radius: 50%;
              display: inline-block;
            }
          }

          .percentage {
            font-size: 13px;
            font-weight: 500;
            color: #333;
            text-align: right;
            min-width: 45px;
            margin-right: 40px;
          }
        }
      }
    }
  }
}

.info-cards-section {
  // display: grid;
  // // grid-template-columns: 1fr 1fr;
  // grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  // gap: 24px;
  // margin-top: 15px;
  // width: 100%;
  // // min-width: 1440px;
  display: flex;
  // flex-wrap: wrap;
  gap: 18px;
  margin-top: 15px;
  width: 100%;

  .info-card {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 540px;
  width: 50%;
  max-width: 50%; 
    // position: relative;
    // border-radius: 8px;
    // overflow: hidden;
    // height: 540px;
    // width: 43.5vw;
    // min-width: 857px;
    // max-width: calc(50% - 12px);
    // margin-right: -5px;


    .card-content {

      .image-container1,
      .image-container2 {
        position: absolute;
        top: -17%;
        left: 0;
        width: 100%;
        height: 100%;

        .doctor-image-one {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top center;
          background-color: white;
        }

        .doctor-image-two {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background-color: white;
        }
      }

      .image-container2 {
        top: -5%;
      }

      .text-content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #003b87;
        overflow: hidden;

        .scroll-container {
          display: flex;
          padding: 15px 24px 35px 24px;
          overflow-x: hidden;
          width: 90%;

          .scroll-item {
            flex: 0 0 100%;
            padding-right: 40px;
            color: white;
            width: calc(100% - 20px);
            transition: transform 0.3s ease;
            min-width: 400px;
            justify-content: center;
            align-items: flex-start;
            height: 50px;

            h2 {
              font-size: 22px;
              font-weight: 600;
              margin: 0;
              margin-bottom: 4px;
              white-space: nowrap;
            }

            p {
              margin: 0;
              font-size: 14px;
              opacity: 0.9;
              white-space: nowrap;
            }
          }
        }

        .scroll-buttons-container {
          position: absolute;
          bottom: 10px;
          right: 15px;
          display: flex;
          gap: 8px;
          z-index: 3;
        }

        .scroll-button {
          position: static;
          transform: none;
          background: none;
          border: none;
          cursor: pointer;
          padding: 0;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 16px;
            height: 16px;
            filter: brightness(0) invert(1);
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

@media (min-width: 1600px) {
  .info-card {
    flex: 1 1 calc(49.5% - 12px);
  }
}
@media screen and (min-width: 1440px) {
  .dashboard-wrapper {
    justify-content: center;
  }

  .dashboard-container {
    max-width: 1920px;
  }
}

// @media screen and (min-width: 1920px) {
//   .info-cards-section .info-card {
//     width: 835px;
//   }
// }