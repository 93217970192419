.login-form-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    max-width: 400px;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .login-form-container h2 {
    text-align: left; /* Align heading to the left */
    margin-bottom: 20px;
  }
  
  .login-options {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .login-option-btn {
    padding: 10px 20px;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    font-size: 14px;
    background: none;
  }
  
  .login-option-btn.active {
    border-bottom: 2px solid blue;
    color: blue;
  }

  .login-forgot-btn {
    padding: 10px 20px;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    font-size: 13px;
    margin-left: 230px;
    margin-top: 3px;
    background: none;
  }
  
  .login-forgot-btn.active {
    border-bottom: 2px solid blue;
    color: blue;
  }
  
  .form-group {
    margin-bottom: 15px;
    position: relative; /* Needed for positioning the eye icon */
  }
  
  .form-group.inline {
    display: flex;
    align-items: center;
  }
  
  .form-group.inline label {
    display: inline;
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 12px; /* Make text smaller */
  }
  
  .form-group.inline input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .form-group input[type="email"],
  .form-group input[type="password"],
  .form-group input[type="text"] {
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid #ccc; /* Add bottom border only */
    outline: none;
    border-radius: 0; /* Remove border-radius */
  }
  
  .form-group input[type="email"]::placeholder,
  .form-group input[type="password"]::placeholder,
  .form-group input[type="text"]::placeholder {
    color: #aaa; /* Placeholder text color */
  }
  
  .password-group {
    display: flex;
    align-items: center;
  }
  
  .password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #aaa;
  }
  
  .password-toggle-icon:hover {
    color: #333;
  }
  
  .forgot-password-link {
    display: block;
    text-align: right;
    margin-bottom: 20px;
    color: #007bff;
    text-decoration: none;
    font-size: 12px; 
    margin-left: 30px;
    margin-top: 20px;
  }
  
  .forgot-password-link:hover {
    text-decoration: underline;
  }
  
  .terms-link {
    color: blue; /* Set link color to blue */
  }

  .input-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* space between elements */
    width: 100%;
  }
  
  .login-form-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff; /* or any preferred color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%; /* full width of parent container */
    max-width: 300px; /* max width */
    transition: background-color 0.3s ease;
  }
  
  .login-form-btn:disabled {
    background-color: #cccccc; /* grey color when disabled */
    cursor: not-allowed;
  }
  
  .login-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px; /* space between Google button and other elements */
    max-width: 300px; /* max width */
  }
  
  .login-buttons .google-login {
    width: 100%;
    max-width: 300px; /* ensure the Google button fits within the container */
  }

  .no-border {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .set-password-container {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .set-password-container .input-otp-fields {
    margin-bottom: 20px;
  }
  
  .set-password-container .input-otp-fields span {
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #333;
  }
  
  .set-password-container .input-otp-fields .MuiTextField-root {
    width: 100%;
  }
  
  .set-password-container .MuiInputAdornment-root {
    margin-right: -8px;
  }
  
  .set-password-container .MuiButton-contained {
    background-color: #1976d2;
    color: #fff;
    font-weight: 500;
  }
  
  .set-password-container .MuiButton-contained:hover {
    background-color: #115293;
  }
  
  .set-password-container .MuiIconButton-root {
    color: #555;
  }
  
  .set-password-container .MuiIconButton-root:hover {
    color: #000;
  }
  
  .set-password-container .MuiTooltip-tooltip {
    font-size: 14px;
    background-color: #333;
    color: #fff;
  }
  
  .set-password-container .MuiFormControl-root {
    margin-top: 10px;
  }
  
  
 
  
  

  
  
  /* .login-form-btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .login-form-btn:hover {
    background-color: #0056b3;
  } */
  