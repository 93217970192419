.permission-wrapper {
  width: 100%;
  // height: 150vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .permission-container {
    // margin: 20px 0;
    width: 95%;
    height: 95%;
  }
  .permission-action {
    margin-top: 30px;
    padding: 20px;
    background-color: #fff;
    width: 95%;
    height: 200px;
    display: flex;
    flex-direction: column;
    h2{
      color: #2D5FA1;
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
    h3 {
      font-size: 16px;
      font-weight: 400;
      
    }
    .permission-dropdown {
      width: 100%;
      height: 50%;
      display: flex;
    }
    .permission-checkbox {
      width: 100%;
      display: flex;
      gap: 100px;
      .lucam-job-checkbox {
      }
    }
  }
}
