.permanent-form-container {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  .permanent-form-wrapper {
    width: 95%;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    .professional-details-form {
      width: 100%;
      // height: 192px;
      padding-bottom: 20px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .pdf-wrapper {
        width: 97%;
        height: 90%;
        .pdf-top {
          width: 100%;
          height: 30%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          img {
          }
          h3 {
          }
        }
        .pdf-bottom {
          width: 100%;
          height: 70%;
          display: flex;
          justify-content: center;
          align-items: center;
          .hospital-input-container {
            width: 93%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            .dropdown-hospital-permanent-job {
              width: 310px;
            }
          }
        }
      }
    }
    .job-details-form {
      margin-top: 10px;
      width: 100%;
      height: 208px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .jdf-wrapper {
        margin-left: 17px;
        width: 70%;
        height: 90%;
        display: flex;
        flex-direction: column;
        .jdf-top {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: 100%;
          height: 20%;
        }
        .jdf-bottom {
          margin-top: 10px;
          width: 100%;
          height: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          .jdf-left {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 25px;
            .input-container {
              border-radius: 8px;
              display: flex;
              width: 328px;
              border: 1px solid #e9e9e980;
              height: 48px;
              justify-content: space-around;
              align-items: center;
              input {
                width: 80%;
                height: 70%;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: #9a9a9a;
                border: none;
                outline: none;
              }
              img {
                cursor: pointer;
              }
            }
            .calendar-date-input {
              position: absolute;
              top: 50px;
            }
          }
          .jdf-right {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 25px;
            .input-container {
              border-radius: 8px;
              display: flex;
              width: 328px;
              border: 1px solid #e9e9e980;
              height: 48px;
              justify-content: space-around;
              align-items: center;
              input {
                width: 80%;
                height: 70%;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: #9a9a9a;
                border: none;
                outline: none;
              }
              img {
                cursor: pointer;
              }
            }
            .calendar-date-input {
              position: absolute;
              top: 50px;
            }
          }
        }
      }
    }
    .job-description-form {
      margin-top: 10px;
      width: 100%;
      height: 235px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .jdf-wrapper {
        width: 97%;
        height: 90%;
        display: flex;
        flex-direction: column;
        .jdf-top {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: 100%;
          height: 20%;
        }
        .jdf-bottom {
          width: 100%;
          height: 80%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 5px;
          label {
            margin: 5px 0px;
            color: #9a9a9a;
            font-weight: 400;
            font-size: 14px;
            line-height: 19.07px;
          }
          textarea {
            background-color: #f7f7f7;
            border-radius: 2px;
            border: none;
            outline: none;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #4d4d4d;
            font-family: "Noto Sans";
          }
        }
      }
    }
    .additional-facilities-form {
      margin-top: 10px;
      width: 100%;
      height: 104px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .aff-wrapper {
        width: 97%;
        height: 90%;
        display: flex;
        flex-direction: column;
        .aff-top {
          height: 40%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          img {
          }
          h3 {
          }
        }
        .aff-bottom {
          height: 60%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 40px;
          gap: 20px;
          .lucam-job-checkbox {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #9a9a9a;
          }
        }
      }
    }
    .contact-details-form {
      margin-top: 10px;
      width: 100%;
      height: 141px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .cdf-wrapper {
        width: 97%;
        height: 90%;
        display: flex;
        flex-direction: column;
        .cdf-top {
          height: 30%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          img {
          }
          h3 {
          }
        }
        .cdf-bottom {
          height: 70%;
          display: flex;
          margin-left: 40px;
          gap: 10px;
          align-items: center;
        }
      }
    }
    .basic-cost-form {
      margin-top: 10px;
      width: 100%;
      height: 392px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .bcf-wrapper {
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        .bcf-top {
          height: 80%;
          width: 100%;
          display: flex;
          flex-direction: column;
          .bcf-top-one {
            width: 100%;
            height: 20%;
            h3 {
            }
          }
          .bcf-top-two {
            width: 100%;
            height: 80%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            .bcf-details {
              display: flex;
              flex-direction: column;
              width: 300px;
              p {
                color: #262626;
                font-weight: 400;
                font-size: 14px;
                line-height: 19.07px;
              }
              label {
                color: #4d4d4d;
                font-weight: 400;
                font-size: 12px;
                line-height: 16.34px;
              }
              span {
                color: #d74f58;
                font-weight: 400;
                font-size: 10px;
                line-height: 13.62px;
              }
            }
          }
        }
        .bcf-bottom {
          height: 20%;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          gap: 8px;
          .post-button {
            background-color: #2d5fa1;
            font-weight: 500;
            font-size: 16px;
            line-height: 21.79px;
            color: #ffffff;
            border-radius: 8px;
            height: 46px;
            width: 144px;
            border: none;
            cursor: pointer;
          }
          .cancel-button {
            cursor: pointer;
            background-color: #ffffff;
            font-weight: 500;
            font-size: 16px;
            line-height: 21.79px;
            color: #2d5fa1;
            border-radius: 8px;
            height: 46px;
            width: 144px;
            border: 1px solid #2d5fa1;
          }
        }
      }
    }
  }
}

.lucum-job-form-title {
  color: #2d5fa1;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.79px;
}
