.openjob-path {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  .openjob-type {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #d74f58;
  }
  img {
  }
  .openjob-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #4d4d4d;
  }
}
