.slider-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .slider-wrapper {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    gap: 20px;
    .ss-image-container {
      width: 60%;
      height: 60%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .ss-details {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h3 {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 19.07px;
        color: #272828;
      }
      p {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 20.24px;
        color: #7a7a7a;
      }
    }
  }
}
