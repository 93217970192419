.recommendation-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  .recommendation-container {
    width: 95%;
    height: 95%;
    margin: 20px 0;
    .recommendation-profiles {
      display: flex;
      gap: 20px;
      .show-profile {
        width: 100%;
        height: auto;
        display: flex;
        gap: 20px;
        justify-content: flex-start;
        align-items: start;
        flex-wrap: wrap;
        background-color: white;
        border-radius: 8px;
        padding: 20px;
      }

      .show-profile-details {
        width: 900px;
        height: auto;
      }
    }
  }
}
