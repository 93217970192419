.dyks-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  div {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    h3 {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 21.79px;
      color: #272828;
    }
    p {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #7a7a7a;
    }
  }
}
