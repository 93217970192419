.range-container {
  position: absolute;
  left: 40%;
  top: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}
.btn-ok {
  background-color: #537fe7;
  height: 45px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
