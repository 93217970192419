.revoke-open-job-container {
  position: absolute;
  top: 30%;
  left: 35%;
  border-radius: 8px;
  background-color: white;
  width: 450px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  .roj-wrapper {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    .roj-top {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #262626;
      }
    }
    .roj-bottom {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn-r-all {
        padding: 0 10px;
        width: auto;
        height: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background-color: #d74f58;
        border-radius: 4px;
        outline: none;
        border: none;
        color: white;
        cursor: pointer;
      }
      .btn-r-keep {
        padding: 0 10px;
        width: auto;
        height: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background-color: #d74f58;
        border-radius: 4px;
        outline: none;
        border: none;
        color: white;
        cursor: pointer;
      }
    }
  }
}
