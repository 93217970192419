.slote-container {
  width: 122px;
  height: 45px;
  background-color: #f2f8ff;
  border: 1px solid lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  .slote-wrapper {
    width: 95%;
    height: 95%;
    text-align: center;
    h3 {
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 14.4px;
      color: #101114;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14.4px;
      color: #1a1c1e;
    }
  }
}
