.login-parent-container {
  width: 95vw;
  display: flex;
  margin: auto;
  height: 100vh;
 
  @media only screen and (max-width: 480px) {
    .login-parent-container{
      overflow-x: hidden;
    }
    // overflow-x: hidden;
  }
  .login-inner-container {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      height: fit-content;
    }

    @media only screen and (max-width: 480px) {
      height: fit-content;
      margin-left: -8px;
      width: 100%;
      // overflow-x: hidden;
    }
    // .login-header {
    //   width: 100%;
    //   padding: 1rem 0;
    //   display: flex;
    //   justify-content: space-between;
    //   div {
    //     > span {
    //       display: none;
    //     }
    //   }
    // }

    .login-header {
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
    }
    
    .login-header > div {
      display: flex;
      align-items: center;
    }
    
    .login-header > div > span {
      display: none;
    }
    
    .login-header img {
      margin-right: 345px; /* Adjust this value according to your design */
    }
    
    #sign-up-button {
      padding: 8px 25px;
      font-weight: bold;
    }
    #login-up-button {
      padding: 8px 25px;
      font-weight: bold;
    }

    //1180px for tab
    @media only screen and (max-width: 1180px) {
      .login-header {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;
      }
      
      .login-header > div {
        display: flex;
        align-items: center;
      }
      
      .login-header > div > span {
        display: none;
      }
      
      .login-header img {
        margin-right: 255px; /* Adjust this value according to your design */
      }
      
      #sign-up-button {
        padding: 8px 25px;
        font-weight: bold;
      }
      
    }

    //480px for mobile
    @media only screen and (max-width: 480px) {
      .login-header {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;
      }
      
      .login-header > div {
        display: flex;
        align-items: center;
      }
      
      .login-header > div > span {
        display: none;
      }
      
      .login-header img {
        margin-right: 305px;
        width: 125.06px;
        height: 28.94px;
        margin-top: -20px;
        
      }
      
      #sign-up-button {
        // padding: 4px 25px;
        padding: 6.95px 27.81px 6.95px 27.81px;
        font-weight: bold;
        width: 119px;
        height: 40px;
        margin-top: -20px;
        // top: 16px;
        // left: 225px;
        margin-left: -170px;
        border-radius: 5.56px;
        gap: 6.95px;
      }

      #login-up-button {
        // padding: 4px 25px;
        padding: 6.95px 27.81px 6.95px 27.81px;
        font-weight: bold;
        width: 119px;
        height: 40px;
        margin-top: -20px;
        // top: 16px;
        // left: 225px;
        margin-left: -170px;
        border-radius: 5.56px;
        gap: 6.95px;
      }
      
    }

    .login-outer-content-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: unset;
      }
      .login-content-left-container {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // width: 500px;
        width: 50%;
        background-image: url("../../asset/Login/LoginContent.png");
        background-repeat: no-repeat;
        // background-size: contain;
        background-size: 200% ;
        background-position: center;
        // text-align: center;
        height: 118%;
        margin-top: -80px;
        // margin-left: 50px;

        @media only screen and (max-width: 480px) {
          width: 328px;
          // background-size: 100% 100%;
          // height: 289px;
          height: 118%;
          margin-top: -2px;
          margin-left: 16px;
          border-radius: 16px;
          
        }

        @media only screen and (max-width: 767px) {
          // width: 100%;
          // background-size: 100% 100%;
          height: 265px;
          width: 100%;
          background-size: 100% 100%;
        }

        div {
          // padding-top: 2rem;
          @media only screen and (max-width: 767px) {
          // padding-top: 2rem;
          margin-left: auto !important;
            margin-right: auto !important;
          }
          > span:first-child {
            font-size: 1rem;
            font-weight: 700;
            display: block;
            padding: 1rem 0;
            color: white;
          }
          > span:last-child {
            font-size: 0.9rem;
            color: white;
          }
          @media only screen and (max-width: 767px) {
            // display: none;
          }
        }
      }

     

      .login-content-left-containerContent {
        display: flex;
        // justify-content: center;
        flex-direction: column;
        // align-items: center;
        margin-left: 70px;
      }
      .login-content-left-containerContent-Div {
        text-align: left;
        color: rgb(255, 255, 255);
        font-family: "Noto Sans";
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;

        @media only screen and (max-width: 767px) {
          margin: 10px;
          font-size: 12px;
          line-height: normal;
          margin: 0;
          padding-top: 2rem;
        }

        @media only screen and (max-width: 480px) {
          #headline {
            width: 324px;
            // height: 50px;
            margin-top: -25px;
            margin-left: 16px;
            text-align: left;
          color: rgb(255, 255, 255);
          font-family: "Noto Sans";
          // font-weight: 700;
          font-size: 14px;
          // line-height: 25px;
          }

      
          
        }
      }
      .login-content-left-containerContent-P {
        // text-align: left;
        // font-family: "Noto Sans";
        // font-weight: 400;
        // font-size: 14px;
        // line-height: 16px;
        // width: 80%;
        // color: rgb(255, 255, 255);
        // margin-top: -2px;
        // margin-left: -0.5rem;

        font-family: "Noto Sans";
        font-weight: "400";
        font-size: "14px";
        line-height: "16px";
        width: "50%";
        color: "#fff";
        margin-top: "-2px";
        margin-left: "3px";
        align-items: "center";
        text-align: "left";

        @media only screen and (max-width: 767px) {
          font-size: 10px;
          width: 67%;
        }

       
      }
      .login-content-right-container {
        height: 100%;
        width: 50%;
        
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .login-inner-content-right-container {
        height: 100%;
        width: 100%;
        margin-left: 20px;
        .login-inner-heading {
          width: 100%;
          padding-bottom: 0.5rem;
          margin-left: 10px;
          
          span {
            display: block;
            padding: 0.5rem 0;
            @media only screen and (max-width: 767px) {
              font-size: 12px;
            }
            @media only screen and (max-width: 480px) {
              font-size: 14px;
              margin-left: -17px;
              // margin-top: -2px;
            
            }
          }
          > span:first-child {
            font-weight: 700;
            font-size: 2rem;
            @media only screen and (max-width: 767px) {
              font-size: 24px;
            }
          }
        }
        .login-inner-tabs {
          display: flex;
          justify-content: space-between;
          margin-left: 10px;
          .Mui-selected {
            background-color: white !important;
            border: 1px solid #00468a;
            border-radius: 3px;

          }
          .MuiTabs-indicator {
            display: none;
          }

          @media only screen and (max-width: 480px) {
          //   display: flex;
          // justify-content: space-between;
          margin-left: -10px;
          .Mui-selected {
            width: 160px;
            height: 10px !important;
            background-color: white !important;
            border: 1px solid #00468a;
            border-radius: 10px;
            font-size: 12px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

          }
          .MuiTabs-indicator {
            display: none;
          }
          }
          
        }
       
        .login-inner-credentials {
          width: 80%;
          height: 50%;
          @media only screen and (max-width: 767px) {
            width: 100%;
            height: unset;
          }
          @media only screen and (max-width: 480px) {
            margin-left: -18px;
            width: 100%;
            height: 100%;
          }
          .MuiBox-root {
            padding: 24px 0 !important;
            @media only screen and (max-width: 767px) {
              padding: 10px 0 !important;
            }
          }
          .input-otp-fields {
            span {
              font-size: 0.8rem;
              display: block;
              padding-bottom: 0.7rem;
            }
            padding: 1rem 0;
          }
          form {
            width: 100%;
            > div {
              padding: 0.5rem;
            }
            .input-fields {
              > span:last-child {
                text-align: end;
                font-size: 0.9rem;
                display: block;
                padding-top: 0.7rem;
              }

              .login-buttons {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 1rem;
                margin-bottom: 1rem;
                @media only screen and (max-width: 767px) {
                  display: none;
                }
              }
            }
          }
        }
        .login-bottom-content {
          width: 100%;
          > div:first-child {
            width: 80%;
            display: flex;
            align-items: center;
            padding: 1rem 0;
            color: grey;
            justify-content: space-evenly;
            div {
              background-color: grey;
              width: 30%;
              height: 1px;
            }
            @media only screen and (max-width: 767px) {
              width: 100%;
              padding: 0;
            }
            @media only screen and (max-width: 480px) {
              margin-left: -20px;
              width: 100%;
              // height: 100%;
            }
          }
          > div:nth-child(2) {
            width: 80%;
            display: flex;
            justify-content: center;
            span {
              a {
                text-decoration: underline;
                color: blue;
              }
            }
            @media only screen and (max-width: 767px) {
              width: 100%;
            }
            @media only screen and (max-width: 480px) {
              margin-left: -30px;
              width: 100%;
              // height: 100%;
            }
          }
          > div:last-child {
            width: 80%;
            display: flex;
            padding-top: 15px;
            justify-content: space-evenly;
            span {
              display: block;
              font-size: 0.7rem;
              color: grey;
            }
            @media only screen and (max-width: 767px) {
              width: 100%;
              justify-content: center;
              gap: 10px;
              padding-top: 5px;
            }
            @media only screen and (max-width: 480px) {
              width: 100%;
              // justify-content: center;
              gap: 10px;
              padding-top: 5px;
              margin-left: -35px;
            }
          }
        }
      }
      // .signup-content-right-container {
      //   width: 45%;
      //   height: 100%;
      //   form {
      //     width: 100%;
      //     height: 100%;
      //     display: grid;
      //     height: 100%;
      //     grid-template-columns: repeat(3, 0.5fr);
      //     grid-template-rows: repeat(9, 0.8fr);
      //     row-gap: 5px;
      //   }

      //   > div:not(div:nth-child(6)),
      //   div:not(div:nth-child(8)),
      //   div:not(div:nth-child(5)),
      //   div:not(div:nth-child(7))
      //    {
      //     grid-column-start: 1;
      //     grid-column-end: 4;
      //   }
      //    div:nth-child(5), div:nth-child(7)
      //   {
      //       grid-column-start: 1;
      //       grid-column-end: 3;
      //   }

      //   div:nth-child(5)
      //   {
      //       grid-area: 5/1/6/3 !important;
      //   }
      //   div:nth-child(7)
      //   {
      //       grid-area: 6/1/7/3 !important;
      //   }
      //   div:nth-child(8)
      //   {
      //       grid-area: 6/3/7/4 I !important;
      //       display: flex;
      //       justify-content: flex-end;
      //       align-items: center;
      //   }
      //   div:nth-child(6)
      //   {
      //       grid-area: 5/3/6/4 !important;
      //       display: flex;
      //       justify-content: flex-end;
      //       align-items: center;
      //   }
      //   div:nth-child(13)
      //   {
      //       grid-area: 11/1/12/2;
      //   }
      //   div:nth-child(14)
      //   {
      //       grid-area: 11/2/12/4 !important;
      //   }
      //   div:first-child
      //   {
      //       span
      //       {
      //           display: block;
      //       }
      //       span:first-child
      //       {
      //           font-size: 1rem;
      //           font-weight: bold;
      //       }
      //       span:last-child
      //       {
      //           font-size: 0.9rem !important;
      //       }
      //   }
      //   div:last-child
      //   {
      //       .css-hyxlzm
      //       {
      //           display: flex !important;
      //       }
      //   }
      // }
    }
  }
}
.MuiSvgIcon-root {
  opacity: 0.5;
}
.MuiSvgIcon-root:hover {
  opacity: 0.8;
}

//Carousel Styles

.awssld {
  background-color: transparent !important;
  margin-top: 0rem !important;
  margin-left: 3.5rem !important;
  margin-right: 0.5rem !important;
  width: 80% !important;

  align-items: start !important;
  padding: 1rem !important;
}

.awssld__wrapper {
  width: 90% !important;
}

.login-content-left-container .awssld__content {
  background-color: #fff !important;
  align-items: start !important;
  // margin-bottom: 1rem !important;
  background-color: transparent !important;
}

.awssld__next {
  color: #111 !important;
  opacity: 1 !important;
}

.awssld__prev {
  color: #111 !important;
  opacity: 1 !important;
}

.login-content-left-container .awssld__timer {
  background-color: transparent !important;
  height: 0px !important;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after {
  background-color: #111 !important;
  width: 20% !important;

  top: none !important;
}
.awssld__controls__arrow-left,
.awssld__controls__arrow-right {
  height: 20px !important;
}
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  background-color: #111 !important;
  width: 20% !important;
  opacity: 1 !important;
  top: none !important;
}

.awssld__controls--active .awssld__controls__arrow-left {
  -webkit-transform: translate3d(-10%, 0, 0) !important;
  transform: translate3d(-10%, 0, 0) !important;
}

.awssld__controls button {
  background-color: transparent !important;
  border-radius: 4px !important;
  height: 15px !important;
  margin-top: 1rem !important;
}

.awssld__controls button .awssld__controls__arrow-right {
  opacity: 1 !important;
}
.awssld__controls button .awssld__controls__arrow-left {
  opacity: 1 !important;
}

// .awssld__controls {
//   margin-top: 3rem !important;
//   margin-left: 1rem !important;
// }

.awssld__controls--active .awssld__controls__arrow-right {
  -webkit-transform: translate3d(10%, 0, 0) !important;
  transform: translate3d(10%, 0, 0) !important;
  opacity: 1 !important;
}