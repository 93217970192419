.jobDetails-container {
  position: absolute;
  // top: 2%;
  // left: 31%;
  // transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  gap: 20px;
  border-radius: 8px;
  background-color: #ffffff;

  overflow: scroll;

  // padding: 20px;
  column-gap: "20px";
  .jobsDetails-inputWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
  }
  p {
    // margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #262626;
  }
  .detailsModal-desc {
    p {
      font-weight: 500;
      padding: 32px 0 8px 0;
      font-size: 14px;
      line-height: 19px;
      color: #b3b3b3;
    }
  }
  .detailsModal-buttonsDiv {
    padding-top: 20px;
    display: flex;
    justify-content: right;
    gap: 24px;
    .detailsModal-buttonsNo {
      padding: 13px 62px;
      width: 146px;
      height: 48px;
      border: 1px solid #2d5fa1;
      border-radius: 8px;
      color: #2d5fa1;
      cursor: pointer;
    }
    .detailsModal-buttonsYes {
      padding: 13px 60px;
      width: 145px;
      height: 48px;
      background: #2d5fa1;
      border-radius: 8px;
      border: none;
      color: #fff;
      cursor: pointer;
    }
  }
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px !important;
}

.overview-bottom {
  // width: 92%;
  height: 80vh;
  display: flex;
  // margin-left: 50px;

  .overview-userlist-wrapper {
    flex: 2;
    display: flex;
    border-right: 1px solid #000;
    flex-direction: column;
    gap: 20px;
    .userlist-top {
      float: right;
      height: 40%;
      width: 100%;
      border-bottom: 5px solid #b3b3b3;
      // border-radius: 8px;
      overflow: hidden;
      .header-container {
        height: 20%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 700;
        margin-left: 22px;
        h3 {
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          color: #262626;
        }
      }
      .scroll-user {
        width: 100%;
        height: 80%;
        overflow: auto;
      }
    }
    .userlist-bottom {
      float: right;
      height: 60%;
      width: 100%;
      // border: 1px solid #b3b3b3;
      border-radius: 8px;
      overflow: hidden;
      .header-container {
        height: 15%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 22px;
        h3 {
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          color: #262626;
          margin: 0;
        }
      }
      .scroll-user {
        width: 100%;
        height: 85%;
        overflow: auto;
      }
    }
  }
  .overview-profile-wrapper {
    flex: 5;
    display: flex;
    flex-direction: column;
    .profile-top {
      flex: 3;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      border-left: 1px solid #000;
      .ov-wrapper {
        width: 90%;
        height: 100%;
        h3 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // height: 65px;
          margin: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 32px;
          color: #262626;
        }
        .ov-down {
          // height: calc(100% - 67px);
          height: 50%;
          display: flex;
          flex-direction: column;
          .ov-top {
            height: 35%;
            width: 100%;
            display: flex;
            .profileov-left {
              width: 70%;
              display: flex;
              box-sizing:border-box;
              .dp-img {
                width: 20%;
                position: relative;
                .dp-badge{
                  position: absolute;
                  z-index: 3;
                  width: 35px;
                  height: 35px;
                  border: 0;
                  top: 90px;
                  left: 32%;
                  aspect-ratio: inherit;
                  overflow: hidden;
                  image-rendering: pixelated;
                }
                .badge-border {
                  border: 4px solid gold;
                }
                
                img {
                  border-radius: 50%;
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  position: absolute;
                  border: 5px solid transparent;
                  overflow: hidden;
                  z-index: 2;
                  // z-index: -1;
                }
              }

              .dp-badge-hover::before{
                content: attr(data-message);
                position: absolute;
                top: 103px;
                left: 80px;
                width: 160px;
                padding: 5px;
                background-color: rgba(255, 217, 0, 0.781);
                color: black;
                font-size: 12px;
                display: none;
                text-align: center;
                border-radius: 10px;
                box-shadow:  0 0 2px gold;
                //font-weight: bold;
              }
              
              .dp-badge-hover:hover::before{
                display: block;
              }

              .dp-img-User {
                width: 20%;
                position: relative;
                .dp-badge{
                  position: absolute;
                  z-index: 3;
                  width: 35px;
                  height: 35px;
                  border: 0;
                  top: 79px;
                  left: 29%;
                  aspect-ratio: inherit;
                  overflow: hidden;
                  image-rendering: pixelated;
                }
                .badge-border {
                  border: 4px solid gold;
                }
                img {
                  border-radius: 50%;
                  width: 90px;
                  height: 90px;
                  object-fit: cover;
                  position: absolute;
                  border: 4px solid transparent;
                  overflow: hidden;
                  z-index: 2;
                  // z-index: -1;
                }
              }
              .dp-badge-hover-User::before{
                content: attr(data-message);
                position: absolute;
                bottom: -30px;
                left: 78px;
                width: 160px;
                padding: 5px;
                background-color: rgba(255, 217, 0, 0.781);
                color: black;
                font-size: 12px;
                display: none;
                text-align: center;
                border-radius: 10px;
                box-shadow:  0 0 2px gold;
                //font-weight: bold;
              }
              
              .dp-badge-hover-User:hover::before{
                display: block;
              }
              .user-info-right {
                width: 70%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // justify-content: space-between;
                padding-left: 40px;
                div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 20px;
                  button {
                    width: auto;
                    padding: 5px;
                    height: 32;
                    color: #ed6c35;
                    background-color: #ffebe2;
                    border-radius: 8px;
                    outline: none;
                    border: none;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
                h3 {
                  margin: 0;
                  // margin: 0;
                  font-weight: 700;
                  font-size: 16px;
                  // line-height: 32px;
                  color: #262626;
                  //padding-left: 20px;
                }
                .dp-tickmark{
                 width: 25px;
                  height: 25px;
                  padding-left: 5px;
                }
                .dp-tickmark-User{
                  width: 25px;
                   height: 25px;
                   padding-left: 5px;
                 }
                p {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #808080;
                  margin: 0;
                }
              }
            }
            .profileov-right {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              width: 30%;
              gap: 10px;
              margin-top: 10px;
              flex-wrap: wrap;
              .schedule-interview {
                cursor: pointer;
                width: 105px;
                height: 55px;
                border-radius: 8px;
                background-color: black;
                color: white;
                font-weight: 500;
                font-size: 16px;
                // line-height: 21.79px;
                outline: none;
                border: 1px solid #173451;
              }
              .invite-sent {
                width: 140px;
                height: 55px;
                border-radius: 8px;
                background-color: green;
                color: white;
                font-weight: 600;
                font-size: 16px;
                line-height: 21.79px;
                outline: none;
                border: 1px solid green;
              }
              .reject {
                cursor: pointer;
                width: 105px;
                height: 55px;
                border-radius: 8px;
                background-color: black;
                color: white;
                font-weight: 500;
                font-size: 16px;
                line-height: 21.79px;
                outline: none;
                border: 1px solid #173451;
              }
              .accept {
                cursor: pointer;
                width: 105px;
                height: 55px;
                border-radius: 8px;
                background-color: black;
                color: #ffffff;
                font-weight: 500;
                font-size: 16px;
                line-height: 21.79px;
                outline: none;
                border: 1px solid #173451;
              }

              .schedule-interview-disabled {
                width: 105px;
                height: 55px;
                border-radius: 8px;
                background-color: grey;
                color: white;
                font-weight: 500;
                font-size: 16px;
                // line-height: 21.79px;
                outline: none;
                border: 1px solid grey;
              }

              .invite-sent-disabled {
                width: 140px;
                height: 55px;
                border-radius: 8px;
                background-color: grey;
                color: white;
                font-weight: 600;
                font-size: 16px;
                line-height: 21.79px;
                outline: none;
                border: 1px solid grey;
              }

              .reject-disabled {
                width: 105px;
                height: 55px;
                border-radius: 8px;
                background-color: grey;
                color: white;
                font-weight: 500;
                font-size: 16px;
                line-height: 21.79px;
                outline: none;
                border: 1px solid grey;
              }

              .accept-disabled {
                width: 105px;
                height: 55px;
                border-radius: 8px;
                background-color: grey;
                color: #ffffff;
                font-weight: 500;
                font-size: 16px;
                line-height: 21.79px;
                outline: none;
                border: 1px solid grey;
              }

              .schedule-interview-invite {
                cursor: pointer;
                width: 140px;
                height: 55px;
                border-radius: 8px;
                background-color: black;
                color: white;
                font-weight: 600;
                font-size: 16px;
                line-height: 21.79px;
                outline: none;
                border: 1px solid #173451;
              }
            }
          }
          .ov-bottom {
            // height: 65%;
            // width: 100%;
            // margin-left: 21%;
            h3 {
              // height: auto;
              font-weight: 500;
              font-size: 20px;
              line-height: 32px;
              color: #262626;
            }
            .pe-container {
              // overflow-y: auto;
              // height: 100px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              overflow-y: auto;
              gap: 10px;
              div {
                h3 {
                  height: 20px;
                  margin: 0;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 28px;
                  color: #4d4d4d;
                }
                .pe-info-details {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  height: 20px;
                  hr {
                    height: 20px;
                  }
                  p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #4d4d4d;
                  }
                }
              }
            }
            .button-view-more {
              height: 10%;
              display: flex;
              // justify-content: center;
              align-items: center;
              button {
                border: none;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #262626;
                cursor: pointer;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    .profile-bottom {
      // overflow-y: auto;
      // height: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-y: auto;
      gap: 10px;
      div {
        h3 {
          height: 40px;
          margin: 0;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: #4d4d4d;
        }
        .pe-info-details {
          display: grid;
          justify-content: center;
          align-items: center;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-auto-flow: row;
          // gap: 30px;
          column-gap: 100px;
          row-gap: 10px;
          > div {
            display: flex;
            flex-direction: column;
            span {
              display: block;
              font-size: 16px;
              line-height: 20px;
              color: #4d4d4d;
            }
          }
          hr {
            height: 30px;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #4d4d4d;
          }
        }
      }
    }
  }
}
.userData-right {
  p {
  }
}
.nc-display {
  width: 90vw;
  display: flex;
  gap: 20px;
}
