.open-job-container {
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  .open-job-wrapper {
    background-color: #f5f5f5;
    height: auto;
    width: 95%;
    margin: 30px 0px;
  }
}
