.user-list-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  .user-list-container {
    width: 95%;
    height: 95%;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
  }
  .user-list-table {
    width: 100%;
  }
}
