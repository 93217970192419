.signup-content-right-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: -30px;
  margin-top: 20px;
  @media only screen and (max-width: 767px) {
  width: 100%;
  }
}
.login-content-left-containerContent-P {
        
  font-family: "Noto Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 10%;
  color: rgb(255, 255, 255);
  margin-top: -2px;
  margin-left: -0.5rem;
  @media only screen and (max-width: 767px) {
  font-size: 10px;
  width: 67%;
  }
    }
.signup-inner-content-right-container {
  height: 100%;
  width: 100%;
  .signup-inner-heading {
    // width: 100%;
    padding-bottom: 1rem;
    display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  // justify-content: center;
  // height: 100%;

    span {
      display: block;
      // padding: 0.5rem 0;
      @media only screen and (max-width: 767px) {
      font-size: 12px;
      }
    }
    // > span:first-child {
    //   font-weight: 700;
    //   font-size: 2rem;
    //   @media only screen and (max-width: 767px) {
    //   font-size: 24px;
    //   }
    // }

    > span:first-child {
      color: blue; /* First span color is blue */
      @media only screen and (max-width: 767px) {
        font-size: 16px; /* Adjust font size for smaller screens */
      }
    }
    > span:last-child {
      color: red; /* Second span color is red */
      @media only screen and (max-width: 767px) {
        font-size: 16px; /* Adjust font size for smaller screens */
      }
    }
  }
   
  .signup-inner-heading2 {
    // width: 100%;
    padding-bottom: 1rem;
    display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  // text-align: "left";
  // justify-content: center;
  // height: 100%;
  margin-top: -10px;

    span {
      // display: block;
      white-space: nowrap;
    
    }

    > span:first-child {
      color: blue; /* First span color is blue */
      margin-left: 170px;
     
    }
    > span:last-child {
      color: red; /* Second span color is red */
      margin-left: 150px;
    
    }
  }


}


// .css-1agwao2-MuiFormControl-root {
//   width: 100%;
// }
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.verifyInput .Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #454545 !important;
}
// .css-b6ew7b-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
//   @media only screen and (max-width: 767px) {

//   border-radius: 8px !important;
//   }
// }
// .css-b6ew7b-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
//   @media only screen and (max-width: 767px) {
//   height: 36px !important;
//   border-radius: 8px !important;

//   }
// }
// .css-o7xo05-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
//   @media only screen and (max-width: 767px) {
//   height: 36px !important;
//   }
// }
// .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
//   @media only screen and (max-width: 767px) {
//     top: -8px !important;
//     font-size: 12px;
//   } 
// }
// .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
//   @media only screen and (max-width: 767px) {
//   height: 36px !important;
//   }
// }