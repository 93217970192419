.landing {
  width: 100%;

  .solutions {
    display: flex;
    flex-direction: column;
    justify-items: center;

    .heading {
      font-family: "DM Sans";
      font-size: 32px;
      letter-spacing: 4.5%;
      line-height: 1.3;
      text-align: center;
    }

    .light_blue_bg_div {
      background-color: #f2f6ff;
      margin-top: 32px;
      padding-top: 44px;
      padding-bottom: 44px;

      .card_section {
        margin-left: auto;
        margin-right: auto;
        max-width: 1360px;
        display: flex;
        justify-content: space-between;
        column-gap: 1.6vw;
      }
    }
  }
  .jobs_listing {
    width: 100%;
    .heading {
      font-family: "DM Sans";
      font-size: 32px;
      letter-spacing: 4.5%;
      line-height: 1.3;
      text-align: center;
    }
    .job_listing_div {
      width: 100%;
      margin-top: 32px;
      padding-top: 24px;
      padding-bottom: 24px;
      margin-left: auto;
      margin-right: auto;
      max-width: 1360px;
      display: flex;
      justify-content: space-between;
      column-gap: 1.6vw;
      
    }
  }
  .how_jobizo_works {
    width: 100%;
    &_heading {
      font-family: "DM Sans";
      font-size: 32px;
      letter-spacing: 4.5%;
      line-height: 1.3;
      text-align: center;
      color: #000000;
    }
    &_desc {
      font-family: "Noto Sans";
      margin-top: 32px !important;
      font-size: 14px;
      line-height: 1.5;
      color: #76777a;
      max-width: 746px;
text-align: center;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    &_light_blue_bg_div {
      background-color: #f2f6ff;
      margin-top: 32px;
      padding: 60px 0;
      // padding: 20px 0;

      .card_section {
        margin-left: auto;
        margin-right: auto;
        max-width: 1360px;
        display: flex;
        justify-content: space-between;
        column-gap: 1.6vw;
      }
     
    }
  }
  .footer {
    background-color: #1b2128;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 94px;

    .footer_container {
      width: 100%;
      max-width: 1160px;
      margin-left: auto;
      margin-right: auto;

      .footer_contact_us {
        background-color: #ffffff;
        box-sizing: border-box !important;
        width: 100%;
        border-radius: 10px;
        padding: 32px 64px 32px 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .first-block {
          font-family: "Poppins";
          color: #101114;
        }
        .second-block {
          .blue_button {
            background-color: #3c59a5;
            padding: 26px 44px 26px 44px;
            border-radius: 8px;
            color: #ffffff;
            font-family: "Poppins";
            border: none;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.35;
          }
        }
      }

      .footer_wrapper {
        max-width: 1074px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        .footer_links_section {
          display: flex;
          flex-direction: row;
          align-items: start;
          justify-content: space-between;

          .footer_link_block {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            margin-top: 18px;
            margin-right: 800px;  //not for permanent

            .footer_links {
              display: flex;
              flex-direction: column;
              row-gap: 10px;
              color: #117fd6;
              font: "Noto Sans";
              font-size: 16px;
              line-height: 1.35;
              .a &:hover {
                text-decoration: underline;
              }
            }
          }

          //not for permanent
          .footer_link_block_2 {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            margin-top: 18px;
            

            .footer_links {
              display: flex;
              flex-direction: column;
              row-gap: 10px;
              color: #117fd6;
              font: "Noto Sans";
              font-size: 16px;
              line-height: 1.35;
              .a &:hover {
                text-decoration: underline;
              }
            }
          }

          .footer_link_block_1 {
            display: flex;
            flex-direction: column;
            background-image: url("../../../asset/footer/subs-background-image.svg");
            box-sizing: border-box !important;
            width: 338px;
            height: 258px;
            margin-top: -15px;
            padding: 32px 42px 32px 42px;
            row-gap: 15px;

            .input_form {
              display: flex;
              align-items: center;
              border: 1.5px solid #e7e8f2;
              border-radius: 6px;
              height: 50px;
              width: 100%;

              .subscribe_field {
                color: #7a7e92;
                height: 50px;
                width: 81%;
                max-width: 208px;
                border: none !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                padding-right: 0 !important;
                padding-left: 16px;
              }
              .submit_button {
                width: 50px;
                height: 100%;
                background-color: #3c59a5;
                border: none !important;
                padding: 0 !important;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                .arrow_icon {
                  width: 13px;
                }
              }
            }
            .footer_desc {
              color: #ffffff;
              font-family: "Noto Sans";
              margin-top: 5px !important;
            }
          }
        }

        .divider {
          background-color: #ffffff;
          height: 1px;
          width: 100%;
          margin-top: 54px;
          margin-bottom: 30px;
        }
        .footer_last_section {
          display: flex;
          flex-direction: row;
          align-items: start;
          justify-content: space-between;

          .footer_logo {
            width: 192px;
          }
          .terms_block {
            display: flex;
            align-items: start;
            justify-content: space-between;
            font-family: "Noto Sans";
            color: #ffffff;
            column-gap: 36px;
          }
          .social_media_block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 15px;
            .social_link {
              width: 35px;
            }
          }
        }
      }
    }
  }
}
.p1 {
  font-size: 32px;
  line-height: 1.5;
}
.p3 {
  font-size: 20px;
  line-height: 1.35;
}
.p4 {
  font-size: 16px;
  line-height: 1.35;
}

.p5 {
  font-size: 14px;
  line-height: 1.35;
}
.p6 {
  font-size: 12px;
  line-height: 1.5;
}
.font-400 {
  font-weight: 400;
}
.font-700 {
  font-weight: 700;
}
p {
  margin: 0px !important;
}
.footer-link-heading {
  font: "Noto Sans";
  font-size: 16px;
  line-height: 1.75;
  color: #ffffff;
}

.img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
section {
  margin-top: 49px;
  margin-bottom: 49px;
}
