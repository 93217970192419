.webOTP {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.reset-webOTP {
  width: 700px;
  height: 500px;
  position: absolute;
  top: 20%;
  @media only screen and (max-width: 767px) {
  width: 90%;
  height: unset;
  
  }
}
.reset-otp-container {
  // width: 720px;
  height: 70vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  .reset-otp-top {
    flex: 5;
    display: flex;
    flex-direction: column;
    // 1---
    .reset-otp-top-closeicon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      .reset-otpcloseIcon {
        transform: scale(1.3);
        cursor: pointer;
      }
    }
    // 2---
    .reset-otp-top-otplabel {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .reset-otp-top-heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 26.46px;
      }
      .reset-otp-top-info {
        width: 80%;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 22.4px;
      }
    }
  }
  .reset-otp-center {
    flex: 5;
    display: flex;
    flex-direction: column;
    .reset-otp-input-box-container {
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      .reset-otp-input-box {
        background-color: rgb(238, 238, 238);
        margin: 10px;
        width: 14%;
        border: 1px solid black;
        font-size: 20px;
        height: 80%;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 31.75px;
        border: none;
        @media only screen and (max-width: 767px) {
          width: 45px !important;
          height: 45px !important;
          font-size: 16px !important;
          margin: 2px !important;
          border-radius: 4px !important;
        }
        &.error {
          border: 1px solid rgb(243, 120, 120);
        }
      }
    }
    .reset-otp-input-details {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span {
      }
    }
  }
  .reset-otp-bottom {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    .reset-otp-button {
      width: 90%;
      height: 56px;
      font-weight: 500;
      cursor: pointer;
      font-size: 14px;
      line-height: 19.6px;
      background-color: #111318;
      outline: none;
      border: none;
      color: white;
      &.disable {
        background-color: #d9dadd;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    height: unset !important;
    padding: 0 15px;
    border-radius: 4px;
  }
}

.resend {
  font-size: 16px;
  line-height: 24px;
  color: #117fd6;
  span {
    font-size: 30px;
  }
  @media only screen and (max-width: 767px) {
  font-size: 12px;
  line-height: unset;
  span {
    font-size: 18px;
  }
  }
}
