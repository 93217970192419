.interview-details {
  width: auto;
  height: 148px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 20px;
  border-radius: 8px;
  scroll-snap-align: start;
  .id-one {
    width: auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    img {
      width: 2px;
      height: 25px;
    }
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 18px;
      color: #4d4d4d;
    }
  }
  .id-two {
    width: 400px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .id-two-left {
      width: 200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding-left: 20px;
      p {
        margin: 0;
        padding: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #4d4d4d;
      }
    }
    .id-two-right {
      width: 150px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #4d4d4d;
      }
      .event-day {
        display: flex;
        gap: 10px;

        label {
          border-radius: 5px;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          color: #2d5fa1;
          background-color: #eafdfc;
          width: 40px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
