.fc .fc-toolbar-title {
  padding: 0 20px;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 18px;
  color: #b3b3b3;
}
.fc-today-button {
  display: none !important;
}
.fc-button-group {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .fc-prev-button {
    outline: none !important;
    border: none !important;
    background-color: #ebf3fe !important;
    width: 40px;
    border-radius: 50% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    .fc-icon {
      color: black !important;
    }
  }
  .fc-next-button {
    outline: none !important;
    border: none !important;
    width: 40px;
    background-color: #ebf3fe !important;
    border-radius: 50% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    .fc-icon {
      color: black !important;
    }
  }
}

.calendar-heading-label {
  width: 100%;
  height: auto;
  padding: 5px 0px 0px 20px;
  h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 18px;
    color: #4d4d4d;
  }
}

tr {
  height: 50px;

  th {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #2d5fa1;
    .fc-scrollgrid-sync-inner {
      height: 100%;
      display: flex !important;
      justify-content: flex-start !important;
      align-items: center !important;
      padding-left: 20px;
    }
  }
}

.fc .fc-daygrid-day-top {
  display: block !important;
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;
  color: #4d4d4d;
  padding-top: 20px;
  padding-left: 20px;
}

.fc-event-main {
  background-color: lightblue !important;
  overflow: hidden !important;
  i {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
    font-style: normal;
  }
}
