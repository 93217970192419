.logout-confirmation-container {
  width: 500px;
  height: 300px;
  background-color: white;
  position: absolute;
  top: 25%;
  left: 33%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  .logout-confirmation-wrapper {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .lc-top {
    }
    .lc-center {
      h3 {
        font-weight: 700;
        line-height: 36.85px;
        font-size: 27.06px;
        color: #101114;
      }
      p {
        font-weight: 500;
        line-height: 24.85px;
        font-size: 18.06px;
        color: #101114;
      }
    }
    .lc-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .button-yes {
        width: 163.47px;
        height: 54.11px;
        border-radius: 8px;
        background-color: #00468a;
        color: #fcfcfc;
        font-weight: 600;
        line-height: 24.85px;
        font-size: 18.06px;
        border: 1px solid #fcfcfc;
        outline: none;
        cursor: pointer;
      }
      .button-no {
        width: 163.47px;
        height: 54.11px;
        border-radius: 8px;
        background-color: white;
        font-weight: 500;
        line-height: 24.85px;
        font-size: 18.06px;
        color: #00468a;
        border: 1px solid #00468a;
        outline: none;
        cursor: pointer;
      }
    }
  }
}
