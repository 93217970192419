.profile-card-container {
  width: 90%;
  height: 80px;
  margin-bottom: 10px;
  margin-left: 20px;
  border-radius: 8px;
  border: 1px solid #b3b3b3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .profile-card-wrapper {
    width: 95%;
    height: 85%;
    display: flex;
    // .user-photo {
    //   width: 45px;
    //   height: 45px;

    //   img {
    //     border-radius: 50%;
    //     height: 100%;
    //     width: 100%;
    //     object-fit: cover;
    //     border-radius: 8px;
    //   }
    // }

    .user-photo {
      width: 20%;
      position: relative;
      .dp-badge{
        position: absolute;
        z-index: 3;
        width: 20px;
        height: 20px;
        border: 0;
        top: 37px;
        left: 22%;
        aspect-ratio: inherit;
        overflow: hidden;
        image-rendering: pixelated;
      }
      .badge-border {
        border: 1px solid gold;
      }
      img {
        border-radius: 50%;
        width: 75%;
        height: 90%;
        object-fit: cover;
        position: absolute;
        border: 2px solid transparent;
        overflow: hidden;
        z-index: 2;
        border-radius: 8px;
        // z-index: -1;
      }
      .dp-badge-hover::before{
        content: attr(data-message);
        position: absolute;
        bottom: -15px;
        left: 40px;
        width: 160px;
        padding: 2px;
        background-color: rgba(255, 217, 0, 0.781);
        color: black;
        font-size: 12px;
        display: none;
        text-align: center;
        border-radius: 10px;
        box-shadow:  0 0 2px gold;
        //font-weight: bold;
      }
      
      .dp-badge-hover:hover::before{
        display: block;
      }
    }

    .user-details {
      width: 50%;
      display: flex;
      flex-direction: column;
      .ud-one {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        h4 {
          margin: 0;
          font-weight: 600;
          font-size: 15px;
          line-height: unset;
          color: #262626;
          text-decoration: none;
          text-overflow: hidden;
        }
        .dp-tickmark{
          width: 20px;
          height: 20px;
          //padding-left: 3px;
         }
      }
      .ud-two {
        flex: 2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          margin: 0;
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          color: #808080;
        }
      }
    }
    .user-experience {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 13px;
        color: #808080;
      }
    }
  }
}
