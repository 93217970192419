.lc-bottom1 {
  padding-top:"30px";
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .button-yes1 {
    width: 163.47px;
    height: 40.11px;
    border-radius: 8px;
    background-color: #00468a;
    color: #fcfcfc;
    font-weight: 600;
    line-height: 24.85px;
    font-size: 18.06px;
    border: 1px solid #fcfcfc;
    outline: none;
    cursor: pointer;
  }
  .button-no1 {
    width: 163.47px;
    height: 40.11px;
    border-radius: 8px;
    background-color: white;
    font-weight: 500;
    line-height: 24.85px;
    font-size: 18.06px;
    color: #00468a;
    border: 1px solid #00468a;
    outline: none;
    cursor: pointer;
  }
}