.sl-filter {
  position: relative;
  background-color: white;
  height: 100px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  padding-left: 20px;
  flex-wrap: wrap;
  .global-search-container {
    // width: 270px;
    width: 170px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid lightgray;
    gap: 15px;

    input {
      width: 70%;
      height: 70%;
      border: none;
      outline: none;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      color: #4d4d4d;
    }
    div {
      width: 15%;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .filter-button-a {
    cursor: pointer;
    width: 41px;
    height: 41px;
    border-radius: 8px;
    border: 1px solid #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
    }
  }
  .date-range-container {
    width: 270px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid lightgray;
    gap: 15px;

    input {
      width: 70%;
      height: 70%;
      border: none;
      outline: none;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      color: #4d4d4d;
    }
    div {
      width: 15%;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

// .date-input {
//   border-radius: 8px;
//   border: 1px solid #9a9a9a;
//   width: 300px;
//   height: 50px;
//   display: flex;
//   align-items: center;
//   justify-content: space-evenly;
//   span {
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 21px;
//     color: #9a9a9a;
//   }
//   .calendar-logo {
//     cursor: pointer;
//     color: lightgray;
//   }
//   .calendar-logo:hover {
//     color: #9a9a9a;
//   }
// }
// .date {
//   position: absolute;
//   right: 0;
//   top: 100px;
//   z-index: 3;
// }
// .user-filter-input {
//   font-size: 14px;
//   font-weight: 400;
//   line-height: 21px;
//   color: #9a9a9a;
// }
