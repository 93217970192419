.calender-wrapper {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  .calender-container {
    width: 95%;
    height: 90%;
    margin: 20px 0;
    .interview-details-container {
      display: flex;
      padding: 0.5rem;
      scroll-snap-type: x mandatory;
      column-gap: 1rem;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      position: relative;
      scroll-behavior: smooth;
    }

    .interview-details-container::-webkit-scrollbar {
      display: none;
    }

    .calender {
      // margin-top: 20px;
      width: 100%;
      height: auto;
      background-color: #ffffff;
      border-radius: 8px;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 40%;
}
