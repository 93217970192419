.shift-logs-container {
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  .shift-logs-wrapper {
    background-color: #f5f5f5;
    height: auto;
    width: 95%;
    margin: 30px 0px;
    .sl-heading {
      background-color: white;
      height: 59px;
      border-radius: 8px;
      display: flex;
      align-items: flex-start;
      align-items: center;
      gap: 10px;
      img {
        margin-left: 10px;
      }
      h3 {
        font-weight: 400;
        font-size: 20px;
        line-height: 27.24px;
        color: #2d5fa1;
      }
    }
    .sl-path {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      .job-type {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #d74f58;
      }
      img {
      }
      .job {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #4d4d4d;
      }
    }
    .sl-filter {
      position: relative;
      background-color: white;
      height: 123px;
      border-radius: 8px;
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      align-items: center;
      padding-left: 20px;
      .filter-button {
        cursor: pointer;
        width: 60px;
        height: 55px;
        border-radius: 8px;
        border: 1px solid #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
        }
      }
      // .date-input {
      //   border-radius: 8px;
      //   border: 1px solid #9a9a9a;
      //   width: 300px;
      //   height: 50px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: space-evenly;
      //   span {
      //     font-size: 14px;
      //     font-weight: 400;
      //     line-height: 21px;
      //     color: #9a9a9a;
      //   }
      //   .calendar-logo {
      //     cursor: pointer;
      //     color: lightgray;
      //   }
      //   .calendar-logo:hover {
      //     color: #9a9a9a;
      //   }
      // }
      // .date {
      //   position: absolute;
      //   right: 0;
      //   top: 100px;
      //   z-index: 3;
      // }
      // .user-filter-input {
      //   font-size: 14px;
      //   font-weight: 400;
      //   line-height: 21px;
      //   color: #9a9a9a;
      // }
    }
    .sl-table {
      background-color: white;
      margin-top: 12px;
      //   height: 85vh;
      border-radius: 8px;
      .raise-query {
        width: 100px;
        height: 35px;
        border-radius: 8px;
        background-color: #ed6c35;
        color: #ffffff;
        font-weight: 500;
        font-size: 13px;
        line-height: 19.07px;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
  }
}
