// .OT_publisher {
//   position: absolute;
//   top: 50%;
//   width: 400px !important;
//   height: 400px !important;
//   right: 0;
//   left: 0;
//   margin-left: auto;
//   margin-right: auto;
// }
// .OT_subscriber{
//   position: absolute;
//   top: 100%;
//   width: 400px !important;
//   height: 400px !important;
//   right: 0;
//   left: 0;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 5%;
// }
#videos {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
display: flex;
flex-direction: row;
gap: 20px;
}

.OT_widget-container {
  width: 400px;
  height: 400px;
}
#publisher {
  position: absolute;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: 0;
  top:0;
  // z-index: 100;
  // border: 1px solid #000;
  border-radius: 3px;
}
#subscriber {
  position: absolute;
  right: 10%;
  top: 5%;
  width: 200px;
  height: 200px;
  // z-index: 10;
  // border: 1px solid #000;
  border-radius: 8px;

}
.OT_publisher {
  width:100vw !important;
  height: 100vh !important;
  border-radius: 8px;

}
.OT_subscriber {
  border-radius: 8px;

}
