.tech_lisiting_card {
    width: 22.6%;
    max-width: 322px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1.1vw 1.1vw 2.2vw 1.1vw;

    .tech_lisiting_card_image {
      width: 100%;
      max-height: 134px;
      max-width: 290px;
      overflow: hidden;
      border-radius:  8px 8px 0px 0px;
    }
    .tech_listing_first_block_title_wrappper {
      font-family: "Noto Sans";
      padding-top: 16px;
      text-align: center;
      .tech_heading {
        font-weight: 500;
        color: #d74f58;
        font-size: 22px;
        line-height: 1.5;
      }
      .tech_desc {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.35;
        color: #000000;
        margin-top: 4px !important;
      }
    }
  }
  .img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }