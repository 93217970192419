.sl-heading {
  background-color: white;
  height: 59px;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  align-items: center;
  gap: 10px;
  img {
    margin-left: 10px;
  }
  h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 27.24px;
    color: #2d5fa1;
  }
}
