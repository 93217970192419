.upcoming-event-container {
  width: 700px;
  height: 550px;
  background-color: #faf9fd;
  position: absolute;
  top: 5%;
  left: 350px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  .upcoming-event-wrapper {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .image-container {
      width: 100%;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .description-upcoming {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 21.79px;
        color: #76777a;
        text-align: center;
      }
      h3 {
        font-size: 30px;
        font-weight: 600;
        color: #2d5fa1;
        font-weight: 700;
        font-size: 4;
      }
      button {
        width: 250px;
        height: 40px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        color: white;
        border-radius: 8px;
        outline: none;
        border: none;
        background-color: #2d5fa1;
        font-weight: 600;
        font-size: 16px;
        line-height: 21.79px;
      }
    }
  }
}
