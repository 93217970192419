.job-info-wrapper {
  display: flex;
  width: 99%;
  gap: 20px !important;
  background: #f3f4f6 !important;
  cursor: default;

  .job-info-left {
    flex: 0 0 60.7%;
    display: flex;
    gap: 20px;
    padding: 24px;
    background: #fff !important;  
    
    .frameParent {
      flex: 1;
      background: #ffffff;
      border-radius: 8px;
      padding: 16px;
      cursor: pointer;
      transition: all 0.3s ease;
      
        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }
    }
  }

  .job-info-right {
    flex: 0 0 33.3%;
    display: flex;
    gap: 20px;
    padding: 24px;
    background: #fff !important;

    .frameParent {
      flex: 1;
      background: #ffffff;
      border-radius: 8px;
      padding: 16px;
      cursor: pointer;
        transition: all 0.3s ease;
      
        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }
    }
  }
}

// First container
.job-info-container-left {  
  flex: 1;
  display: flex;
  gap: 20px;
}

// Second container
.job-info-container-right {
  flex: 1;
  display: flex;
  gap: 20px;
}

// Common styles for both containers
.job-details {
  .jd-top {
    p {
      color: #6b7280;
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .jd-bottom {
    margin-top: 7px;

    .job-label-icon {
      display: flex;
      align-items: center;
      gap: 12px;

      img {
        width: 40px;
        height: 40px;
        padding: 8px;
        border-radius: 50%;
        background: #e6f7ff;
      }

      p {
        font-size: 24px;
        font-weight: 600;
        color: #111827;
        margin: 0;
      }
    }
  }
}

// Responsive styles
@media (max-width: 1200px) {
  .job-info-wrapper {
    gap: 16px;
  }

  .job-info-left,
  .job-info-right {
    gap: 16px;
    padding: 16px;
  }
}

@media (max-width: 768px) {
  .job-info-wrapper {
    flex-direction: column;
  }

  .job-info-left,
  .job-info-right {
    width: 100%;
    gap: 12px;
    padding: 12px;
  }
}

// Add/Update responsive styles at the end
@media screen and (max-width: 768px) {
  .job-info-wrapper {
    flex-direction: column;
    gap: 15px;
    padding: 10px;

    // Left section
    .job-info-left {
      flex: none;
      width: 100%;
      flex-direction: column;
      padding: 15px;
      gap: 15px;

      .frameParent {
        width: 100%;
        margin-bottom: 10px;
        
        &:last-child {
          margin-bottom: 0;
        }
      }

      .job-info-container-left {
        flex-direction: column;
        width: 100%;
        gap: 15px;
      }
    }

    // Right section
    .job-info-right {
      flex: none;
      width: 100%;
      flex-direction: column;
      padding: 15px;
      gap: 15px;

      .frameParent {
        width: 100%;
        margin-bottom: 10px;
        
        &:last-child {
          margin-bottom: 0;
        }
      }

      .job-info-container-right {
        flex-direction: column;
        width: 100%;
        gap: 15px;
      }
    }
  }

  // Adjust card content for mobile
  .job-details {
    .jd-top {
      p {
        font-size: 11px;
      }
    }

    .jd-bottom {
      .job-label-icon {
        gap: 10px;

        img {
          width: 35px;
          height: 35px;
          padding: 6px;
        }

        p {
          font-size: 20px;
        }
      }
    }
  }
}

// Tablet view adjustments
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .job-info-wrapper {
    gap: 15px;
    
    .job-info-left,
    .job-info-right {
      padding: 15px;
      gap: 15px;
    }
  }
}
