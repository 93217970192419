.job-popup-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 488px;
  height: 216px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.job-popup-heading1 {
  
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #4D4D4D;
}
.job-popup-heading2 {
  font-weight: 600;
font-size: 24px;
line-height: 32px;
color: #262626;
}
.cancelButton {
  padding: 13px 62px;
gap: 10px;
border: 1px solid #2D5FA1;
border-radius: 8px;
width: 146px;
height: 48px;
color: #2D5FA1;
background-color: #fff;
font-weight: 600;
cursor: pointer;
}
.confirmButton {
  padding: 13px 60px;
gap: 10px;
width: 145px;
height: 48px;
background: #2D5FA1;
border-radius: 8px;
color: #ffffff;
border:none;
font-weight: 600;
cursor: pointer;
}



@media only screen and (max-width: 480px) {
  .job-popup-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%; /* Adjust width for responsiveness */
    max-width: 488px; /* Limit maximum width */
    height: auto; /* Change height to auto for responsiveness */
    max-height: 100%; /* Limit maximum height */
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .job-popup-heading1 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #4d4d4d;
    margin: 10px;
  }
  
  .job-popup-heading2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #262626;
  }
  
  .cancelButton {
    padding: 13px 20px; /* Adjust padding for smaller screens */
    gap: 10px;
    border: 1px solid #2d5fa1;
    border-radius: 8px;
    // width: 100%; /* Take up full width */
    max-width: 146px; /* Limit maximum width */
    height: 48px;
    color: #2d5fa1;
    background-color: #fff;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .confirmButton {
    padding: 13px 20px; /* Adjust padding for smaller screens */
    gap: 10px;
    // width: 100%; /* Take up full width */
    max-width: 145px; /* Limit maximum width */
    height: 48px;
    background: #2d5fa1;
    border-radius: 8px;
    color: #ffffff;
    border: none;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
}