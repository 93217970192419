.webOTP {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.w-otp-container {
  // width: 720px;
  height: 70vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  .w-otp-top {
    flex: 5;
    display: flex;
    flex-direction: column;
    // 1---
    .w-otp-top-closeicon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      .w-otpcloseIcon {
        transform: scale(1.3);
        cursor: pointer;
      }
    }
    // 2---
    .w-otp-top-otplabel {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .w-otp-top-heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 26.46px;
      }
      .w-otp-top-info {
        width: 80%;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 22.4px;
        @media only screen and (max-width: 767px) {
          width: 100% !important;
          line-height: unset !important;
          font-size: 12px !important;
          margin-bottom: 20px;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      align-items: start !important;
    }
  }
  .w-otp-center {
    flex: 5;
    display: flex;
    flex-direction: column;
    .w-otp-input-box-container {
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 767px) {
        gap: 10px;
      }
      .w-otp-input-box {
        background-color: grey;
        margin: 10px;
        width: 14%;
        border: 1px solid black;
        font-size: 20px;
        height: 80%;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 31.75px;
        border: none;
        @media only screen and (max-width: 767px) {
          width: 45px !important;
          height: 45px !important;
          font-size: 16px !important;
          margin: 2px !important;
          border-radius: 4px !important;
        }
        &.error {
          border: 1px solid rgb(243, 120, 120);
        }
      }
    }
    .w-otp-input-details {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span {
      }
    }
  }
  .w-otp-bottom {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    .w-otp-button {
      width: 90%;
      height: 56px;
      font-weight: 500;
      cursor: pointer;
      font-size: 14px;
      line-height: 19.6px;
      background-color: #111318;
      outline: none;
      border: none;
      color: white;
      &.disable {
        background-color: #d9dadd;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    height: unset !important;
    padding: 0 15px;
  }
}
.resent-timer {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  margin: 10px auto;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
}
.bottom-verify-button {
  margin: 2rem 0px;
  width: 85%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 767px) {
    margin: 1rem 0px;
    width: 100%;
  }
}
