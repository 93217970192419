.locum-form-container {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  .locum-form-wrapper {
    width: 95%;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    .professional-details-form {
      width: 100%;
      // height: 192px;
      padding-bottom: 20px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .pdf-wrapper {
        width: 97%;
        height: 90%;
        .pdf-top {
          width: 100%;
          height: 30%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          img {
          }
          h3 {
          }
        }
        .pdf-bottom {
          width: 100%;
          height: 70%;
          display: flex;
          justify-content: center;
          align-items: center;
          .hospital-input-container {
            width: 93%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            .dropdown-hospital-locum-job {
              width: 310px;
            }
          }
        }
      }
    }


    .job-details-form-main-container {
      background-color: white;
      display: flex;
      flex-direction: column;
    }
    .job-details-form {
      margin-top: 10px;
      width: 100%;
      height: 328px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .jdf-wrapper {
        margin-left: 17px;
        width: 800px;
        height: 100%;
        display: flex;
        flex-direction: column;
        .jdf-top {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: 100%;
          height: 20%;
        }
        .jdf-bottom {
          margin-top: 10px;
          width: 100%;
          height: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          .jdf-left {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 25px;
            .input-container {
              border-radius: 8px;
              display: flex;
              width: 328px;
              border: 1px solid #e9e9e980;
              height: 48px;
              justify-content: space-around;
              align-items: center;
              input {
                width: 80%;
                height: 70%;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: black;
                border: none;
                outline: none;
              }
              img {
                cursor: pointer;
              }
            }
            .calendar-date-input {
              position: absolute;
              top: 50px;
            }
          }
          .jdf-right {
            position: relative;
            width: 100%;
            height: 100%;
            margin-left: 2px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 25px;
            .input-container {
              border-radius: 8px;
              display: flex;
              width: 328px;
              border: 1px solid #e9e9e980;
              height: 48px;
              justify-content: space-around;
              align-items: center;
              input {
                width: 80%;
                height: 70%;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: black;
                border: none;
                outline: none;
              }
              img {
                cursor: pointer;
              }
            }
            .calendar-date-input {
              position: absolute;
              top: 50px;
            }
          }
        }
      }
      .shift-wrapper {
        width: 500px;
        height: 100%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        .shift-container {
          width: 100%;
          height: 90%;
          background-color: #fdfbff;
          display: flex;
          justify-content: center;
          align-items: center;
          .main-shift-container {
            width: 90%;
            height: 90%;
            .shift-top {
              h3 {
                display: flex;
                justify-content: flex-start;
                gap: 10px;
                align-items: center;
                font-size: 12px;
                font-weight: 600;
                line-height: 16.34px;
                color: #2d5fa1;
              }
              p {
                font-size: 14px;
                font-weight: 500;
                line-height: 19px;
                color: #101114;
              }
            }
            .shift-bottom {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
            }
          }
        }
      }
    }
    .job-description-form {
      margin-top: 10px;
      width: 100%;
      height: 235px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .jdf-wrapper {
        width: 97%;
        height: 90%;
        display: flex;
        flex-direction: column;
        .jdf-top {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: 100%;
          height: 20%;
        }
        .jdf-bottom {
          width: 100%;
          height: 80%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 5px;
          label {
            margin: 5px 0px;
            color: #9a9a9a;
            font-weight: 400;
            font-size: 14px;
            line-height: 19.07px;
          }
          textarea {
            background-color: #f7f7f7;
            border-radius: 2px;
            border: none;
            outline: none;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #4d4d4d;
            font-family: "Noto Sans";
          }
        }
      }
    }
    .additional-facilities-form {
      margin-top: 10px;
      width: 100%;
      height: 104px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .aff-wrapper {
        width: 97%;
        height: 90%;
        display: flex;
        flex-direction: column;
        .aff-top {
          height: 40%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          img {
          }
          h3 {
          }
        }
        .aff-bottom {
          height: 60%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 40px;
          gap: 20px;
          .lucam-job-checkbox {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #9a9a9a;
          }
        }
      }
    }
    .contact-details-form {
      margin-top: 10px;
      width: 100%;
      height: 141px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .cdf-wrapper {
        width: 97%;
        height: 90%;
        display: flex;
        flex-direction: column;
        .cdf-top {
          height: 30%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          img {
          }
          h3 {
          }
        }
        .cdf-bottom {
          height: 70%;
          display: flex;
          margin-left: 40px;
          gap: 10px;
          align-items: center;
        }
      }
    }
    .basic-cost-form {
      margin-top: 10px;
      width: 100%;
      height: 392px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .bcf-wrapper {
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        .bcf-top {
          height: 80%;
          width: 100%;
          display: flex;
          flex-direction: column;
          .bcf-top-one {
            width: 100%;
            height: 20%;
            h3 {
            }
          }
          .bcf-top-two {
            width: 100%;
            height: 80%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            .bcf-details {
              display: flex;
              flex-direction: column;
              width: 300px;
              p {
                color: #262626;
                font-weight: 400;
                font-size: 14px;
                line-height: 19.07px;
              }
              label {
                color: #4d4d4d;
                font-weight: 400;
                font-size: 12px;
                line-height: 16.34px;
              }
              span {
                color: #d74f58;
                font-weight: 400;
                font-size: 10px;
                line-height: 13.62px;
              }
            }
          }
        }
        .bcf-bottom {
          height: 20%;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          gap: 8px;
          .post-button {
            background-color: #2d5fa1;
            font-weight: 500;
            font-size: 16px;
            line-height: 21.79px;
            color: #ffffff;
            border-radius: 8px;
            height: 46px;
            width: 144px;
            border: none;
            cursor: pointer;
          }
          .cancel-button {
            cursor: pointer;
            background-color: #ffffff;
            font-weight: 500;
            font-size: 16px;
            line-height: 21.79px;
            color: #2d5fa1;
            border-radius: 8px;
            height: 46px;
            width: 144px;
            border: 1px solid #2d5fa1;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  body {
    overflow-x: hidden;
  }

  .locum-form-container {
    width: 52%;
    // height: 100%;
    margin-left: 0px;
    background-color: #f5f5f5;
    // display: flex;
    justify-content: center;
    align-items: center;
    // overflow-x: hidden;
    .locum-form-wrapper {
      width: 95%;
      margin: 20px 0px;
      // display: flex;
      // flex-direction: column;
      .professional-details-form {
        width: 100%;
        // height: 192px;
        padding-bottom: 20px;
        background-color: white;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        .pdf-wrapper {
          width: 97%;
          height: 90%;
          .pdf-top {
            width: 100%;
            height: 30%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            img {
            }
            h3 {
            }
          }
          .pdf-bottom {
            width: 100%;
            height: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
            .hospital-input-container {
              width: 93%;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              gap: 10px;
              .dropdown-hospital-locum-job {
                width: 310px;
              }
            }
          }
        }
      }
  
  
      .job-details-form-main-container {
        margin-top: 10px;
        background-color: white;
        display: flex;
        flex-direction: column;
    }
    
    .job-details-form {
        margin-top: 10px;
        width: 100%;
        height: auto; /* Change height to auto for responsiveness */
        background-color: white;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .jdf-wrapper {
        margin-left: 17px;
        width: 100%; /* Adjust width for responsiveness */
        height: auto; /* Change height to auto for responsiveness */
        display: flex;
        flex-direction: column;
    }
    
    .jdf-top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
        height: auto; /* Change height to auto for responsiveness */
    }
    
    .jdf-bottom {
        margin-top: 10px;
        width: 100%;
        height: auto; /* Change height to auto for responsiveness */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; /* Change flex direction for responsiveness */
    }
    
    .jdf-left,
    .jdf-right {
        position: relative;
        width: 100%;
        margin-bottom: 20px; /* Add margin bottom for spacing */
    }
    
    .input-container {
        border-radius: 8px;
        display: flex;
        width: 90%; /* Adjust width for responsiveness */
        max-width: 328px; /* Add max-width for responsiveness */
        border: 1px solid #e9e9e980;
        height: 48px;
        justify-content: space-around;
        align-items: center;
    }
    
    .input-container input {
        width: 80%;
        height: 70%;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: black;
        border: none;
        outline: none;
    }
    
    .calendar-date-input {
        position: absolute;
        top: 50px;
    }
    
    .shift-wrapper {
        width: 70%;
        height: auto; /* Change height to auto for responsiveness */
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .shift-container {
        width: 100%;
        height: auto; /* Change height to auto for responsiveness */
        background-color: #fdfbff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .main-shift-container {
        width: 90%;
        height: auto; /* Change height to auto for responsiveness */
    }
    
    .shift-top h3 {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 16.34px;
        color: #2d5fa1;
    }
    
    .shift-top p {
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        color: #101114;
    }
    
    .shift-bottom {
      width: 70%; /* Ensure the items fill the width */
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center; /* Center items horizontally */
      align-items: center;
    }

    
    .job-description-form {
      margin-top: 10px;
      width: 100%;
      height: auto; /* Change height to auto for responsiveness */
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .jdf-wrapper {
      width: 97%;
      height: auto; /* Change height to auto for responsiveness */
      display: flex;
      flex-direction: column;
  }
  
  .jdf-top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      width: 100%;
      height: auto; /* Change height to auto for responsiveness */
  }
  
  .jdf-bottom {
      width: 100%;
      height: auto; /* Change height to auto for responsiveness */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 5px;
  }
  
  label {
      margin: 5px 0px;
      color: #9a9a9a;
      font-weight: 400;
      font-size: 14px;
      line-height: 19.07px;
  }
  
  textarea {
      background-color: #f7f7f7;
      border-radius: 2px;
      border: none;
      outline: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #4d4d4d;
      font-family: "Noto Sans";
      width: 95%; /* Ensure textarea takes up full width */
      min-height: 100px; /* Set minimum height for textarea */
      margin-bottom: 20px;
  }
  


  .additional-facilities-form {
    margin-top: 10px;
    width: 100%;
    height: auto; /* Change height to auto for responsiveness */
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aff-wrapper {
    width: 97%;
    height: auto; /* Change height to auto for responsiveness */
    display: flex;
    flex-direction: column;
}

.aff-top {
    height: auto; /* Change height to auto for responsiveness */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.aff-bottom {
  height: auto; /* Change height to auto for responsiveness */
  display: flex;
  flex-direction: column; /* Change flex direction to column */
  align-items: flex-start; /* Align items to the start */
  // margin-left: 0px; /* Remove margin left for mobile layout */
  margin-right: 260px; /* Push items to the left side */
  // gap: 10px;
  margin-bottom: 20px;
}

.aff-bottom .lucam-job-checkbox {
  margin-bottom: -20px; /* Set equal vertical gap */
}
.first-input {
  margin-left: -90px;
}
.second-input {
  margin-left: -55px;
}
.third-input {
  margin-left: -10px;
}


.lucam-job-checkbox {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #9a9a9a;
    white-space: nowrap; /* Prevent text from wrapping */
    margin-bottom: 10px; /* Add margin bottom for spacing between items */
}

      
.contact-details-form {
  margin-top: 10px;
  width: 100%;
  height: auto; /* Change height to auto for responsiveness */
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cdf-wrapper {
  width: 100%;
  height: auto; /* Change height to auto for responsiveness */
  display: flex;
  flex-direction: column;
}

.cdf-top {
  height: auto; /* Change height to auto for responsiveness */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.cdf-bottom {
  height: auto; /* Change height to auto for responsiveness */
  display: flex;
  flex-direction: column; /* Change flex direction to column */
  margin-left: 0; /* Remove margin left for mobile layout */
  gap: 20px;
  align-items: flex-start; /* Align items to start */
  margin-bottom: 10px;
}

.cdf-bottom > * {
  width: 100%; /* Ensure elements take up full width */
}

.cdf-bottom > * + * {
  margin-top: 20px; /* Increase the vertical gap between all columns */
}





.basic-cost-form {
  margin-top: 10px;
  width: 100%;
  height: auto; /* Change height to auto for responsiveness */
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bcf-wrapper {
  width: 90%;
  height: auto; /* Change height to auto for responsiveness */
  display: flex;
  flex-direction: column;
}

.bcf-top {
  height: auto; /* Change height to auto for responsiveness */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bcf-top-one {
  width: 100%;
  height: auto; /* Change height to auto for responsiveness */
  h3 {
      /* Add styles as needed */
  }
}

.bcf-top-two {
  width: 100%;
  height: auto; /* Change height to auto for responsiveness */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.bcf-details {
  display: flex;
  flex-direction: column;
  width: 100%; /* Adjust width for responsiveness */
  margin-bottom: 10px; /* Add margin bottom for spacing */
  p {
      color: #262626;
      font-weight: 400;
      font-size: 14px;
      line-height: 19.07px;
  }
  label {
      color: #4d4d4d;
      font-weight: 400;
      font-size: 12px;
      line-height: 16.34px;
  }
  span {
      color: #d74f58;
      font-weight: 400;
      font-size: 10px;
      line-height: 13.62px;
  }
}

.bcf-bottom {
  height: auto; /* Change height to auto for responsiveness */
  width: 100%;
  display: flex;
  justify-content: space-between; /* Space out buttons */
  align-items: center;
  gap: 8px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.post-button {
  margin-right: auto; /* Move the "Post" button to the left */
}

.post-button,
.cancel-button {
  background-color: #2d5fa1;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.79px;
  color: #ffffff;
  border-radius: 8px;
  height: 46px;
  width: 180px !important;
  padding: 0 20px;
  border: none;
  cursor: pointer;
}

.cancel-button {
  cursor: pointer;
  background-color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.79px;
  color: #2d5fa1;
  border-radius: 8px;
  border: 1px solid #2d5fa1;
}

    }
  }
}


.lucum-job-form-title {
  color: #2d5fa1;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.79px;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #9a9a9a !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #9a9a9a !important;
}

.css-1agwao2-MuiFormControl-root {
  margin: 0 !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #9a9a9a !important;
  border-color: #9a9a9a !important;
}
.css-1v4ccyo.Mui-error .MuiOutlinedInput-notchedOutline {
  color: #9a9a9a !important;
  border-color: #9a9a9a !important;
}
.css-u9osun.Mui-error {
  color: #9a9a9a !important;
  border-color: #9a9a9a !important;
}

.css-1v4ccyo.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #9a9a9a !important;
}
.MuiOutlinedInput-notchedOutline {
  // color: #9a9a9a !important;
  outline: #9a9a9a !important;
  border-color: #9a9a9a !important;
  // border: #9a9a9a !important;
}
.jd-textarea {
  resize: none;
}

// .css-u791bf-MuiPickersArrowSwitcher-root-MuiClockPicker-arrowSwitcher {
//   display: none !important;
// }

.MuiPickersArrowSwitcher-root{
  display: none !important;
}

.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #9a9a9a !important;
}

.extradays-strong-toolkit {
  color: black;
  font-size: 10px;
  cursor: pointer;
}

.bcf-details p {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.bcf-details p svg {
  margin-left: 5px;
}


//additional skills css
.job-posting-shared-job-skill-typeahead__skills-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.selectable-button {
  background-color: #f0f2f5;
  border: 1px solid #d1d9e1;
  border-radius: 20px;
  padding: 8px 16px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.selectable-button:hover {
  // background-color: #e4e7eb;
  // background-color: rgb(228, 222, 222);
  // color: black;
  // font-weight: bold;
  // background-color: green;
  background-color: #0073b1;
}

.selected-skill {
  background-color: #0073b1;
  color: #ffffff;
  border-color: #0073b1;
}

.selectable-button .artdeco-pill__icon {
  margin-left: 8px;
}

.t-14.t-black.mv2 {
  background-color: #0073b1;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.t-14.t-black.mv2:hover {
  background-color: #005a8d;
}

.t-blue {
  color: #2d5fa1;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.79px;
}


