.card_wrapper {
    width: 22.6%;
    max-width: 322px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding-bottom: 2vw;

    .first_block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2vw 2.2vw 0 2.2vw;

      .card_title_desc_wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        .card_title{
          font-family: "Noto Sans";
          font-size: 28px;
          font-weight: 400;
          line-height: 1.4;
          color:#000000;
        }

        .red_text{
          font-family: "Noto Sans";
          font-size: 22px;
          font-weight: 500;
          line-height: 1.5;
          color:#D74F58;
        }
      }

      .card_icon {
        width: 48px;
        border-radius: 8px;
      }
    }
    .card_desc {
      font-family: "Noto Sans";
      padding: 0 2.2vw 0 2.2vw;
      font-size: 12.67px;
      line-height: 19px;
      color: #000000;
    }
    .third_block {
      margin: 32px auto 0 auto;
      box-sizing: border-box !important;
      width: 90%;
      max-width: 289px;
      background-color: #3c59a5;
      border-radius: 8px;
      border: none;

      .know_button {
        box-sizing: border-box !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: "DM Sans";
        color: #ffffff;
        font-weight: 600;
        line-height: 18px;
        height: 40px;
        width: 100%;
        border: none;
      }
    }
  }