.job_lisiting_card {
    width: 22.6%;
    // padding: 2vw 2.2vw 2vw 2.2vw;
    max-width: 322px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.11);

    .job_listing_first_block {
      background-color: #f2f6ff;
      padding: 1.1vw 1.1vw 1.1vw 1.1vw;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .job_listing_first_block_search_wrappper {
        border: 1px solid #e3e2e6;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 8px;
        width: 124px;
        border-radius: 8px;

        .search_icon {
          width: 20px;
        }
        p {
          color: #101114;
          font-family: "Noto Sans";
          font-size: 12px;
          font-weight: 400;
          line-height: 1.35;
          background-color: transparent;
        }
      }
      .job_listing_first_block_title_wrappper {
        font-family: "Noto Sans";
        .job_heading {
          font-weight: 600;
          color: #101114;
          font-size: 18px;
          line-height: 1.35;
        }
        .job_pay {
          font-weight: 400;
          font-size: 14px;
          line-height: 1.4;
          color: #46474a;
        }
      }
    }
    .job_listing_second_block {
      padding: 8px 0 8px 16px;
      display: flex;
      column-gap: 8px;

      .hospital_icon {
        height: 56px;
        width: 56px;
        // filter: blur(5px);  //not for permanent
      }
      .hospital_address {
        font-family: "Noto Sans";
        // filter: blur(5px); // not for permanent
        .hospital_name {
          font-size: 14px;
          font-weight: 500;
          line-height: 1.4;
          color: #101114;
        }
        .hospital_location {
          font-size: 14px;
          font-weight: 500;
          line-height: 1.4;
          color: #46474a;
        }
      }
    }
    .divider {
      height: 1px;
      background-color: #f1f0f4;
      max-width: 306px;
      width: 95%;
      margin-left: auto;
      margin-right: auto;
    }
    .job_listing_third_block {
      padding: 1.1vw 1.1vw 1.1vw 1.9vw;

      .job_listing_deadline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: "Noto Sans";
        font-weight: 400;

        .job_listing_details {
          display: flex;
          flex-direction: column;

          .job_listing_detail_heading {
            color: #76777a;
            font-size: 12px;
            line-height: 1.33;
          }
          .job_listing_detail {
            color: #101114;
            font-size: 14px;
            line-height: 1.4;
          }
        }
      }
      .job_listing_employer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        .job_listing_employer_details {
          display: flex;
          column-gap: 8px;
          max-width: 190px;
          align-items: center;
          justify-content: start;
          font-family: "Noto Sans";
          font-weight: 400;
          font-size: 12px;
          line-height: 1.33;

          &_heading {
            color: #76777a;
          }
          .detail {
            color: #101114;
          }
        }
        .job_listing_employer_view_details {
          color: #117fd6;
          display: flex;
          font-family: "Noto Sans";
          align-items: center;
          justify-content: start;
          font-size: 12px;

          p {
            font-weight: 600;
            line-height: 1.33;
          }
          .right_arrow {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  p {
    margin: 0px !important;
  }