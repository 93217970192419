.post-wrapper {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  .post-container {
    width: 85%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    .post-left {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      div {
        flex: 1;
        h3 {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #7a7a7a;
        }
        p {
          margin: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 19.07px;
          color: #afafaf;
        }
        .postHeader {
          margin: 0;
          font-weight: 500;
          font-size: 20px;
          line-height: 27.24px;
          color: #000000;
        }
      }
    }
    // .post-right {
    //   height: 85%;
    //   width: 50%;
    // }
  }
}
