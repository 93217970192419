/* 
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-logo img {
    height: 40px;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    gap: 32px;
    font-family: 'Noto Sans', 
  }
  
  .navbar-links a {
    margin-right: 20px;
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropbtn {
    background: none;
    border: none;
    font-size: 16px;
    color: #333;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    min-width: 160px;
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .navbar-buttons {
    display: flex;
    gap: 32px;
  }
  
  .login-btn, .register-btn {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
    border-radius: 10px;
    transition: background-color 0.3s;
  }
  
  .login-btn {
    background-color: white;
    color: #333;
    border: 1px solid #007bff;
    margin-right: 80px;
    width: 140px;
  }
  
  .register-btn {
    background-color: navy;
    color: white;
  }
  
  .login-btn:hover, .register-btn:hover {
    background-color: #0056b3;
    color: white;
  }

  .container{
    flex-wrap: wrap;
    gap: 16px;
    display: flex;
  }


  .signup-form {
    position: absolute;
    top: 87px; 
    right: 30px; 
    z-index: 999; 
}

.login-form-container {
    position: absolute;
    top: 87px; 
    right: 60px; 
    z-index: 999; 
}


.hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 20px;
    background-image: url('../../asset/navbar/african_doctor_images.svg');
    background-size: cover;
    background-position: center;
    color: white;
    background-color: navy;
    width: 96%;
    position: relative;
    min-height: 400px;
  }
  
  .hero-content {
    max-width: 50%;
  }
  
  .hero-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .hero-content .register-btn {
    background-color: #f04a42;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .hero-image {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -15px;
  }
  
  .hero-image img {
    width: 100%;
    height: auto;
    max-height: 500px; 
    object-fit: contain;
    margin-top: -15px;
  }

  .bot-image {
    position: absolute;
    bottom: 0;
    left: 50%; 
    transform: translateX(-35%);
    z-index: 1; 
} 

.bot-image img {
    width: 180px; 
    height: auto;
}
  

.desktop-image {
    position: absolute;
    bottom: 7px;
    left: 50%; 
    transform: translateX(12%);
    z-index: 1; 
} 

.desktop-image img {
    width: 600px; 
    height: auto;
}
   */

   /* src/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap; /* Ensures items wrap on smaller screens */
}

.navbar-logo img {
  height: 40px;
}

.navbar-links {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  gap: 16px; /* Reduced gap for better fit on smaller screens */
  font-family: 'Noto Sans', sans-serif; /* Fixed incomplete font-family declaration */
  flex-wrap: wrap; /* Ensures items wrap on smaller screens */
}

.navbar-links a {
  margin-right: 10px; /* Reduced margin for better fit on smaller screens */
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background: none;
  border: none;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.navbar-buttons {
  display: flex;
  gap: 16px; /* Reduced gap for better fit on smaller screens */
  flex-wrap: wrap; /* Ensures items wrap on smaller screens */
}

.login-btn, .register-btn {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px; /* Reduced margin for better fit on smaller screens */
  border-radius: 10px;
  transition: background-color 0.3s;
}

.login-btn {
  background-color: white;
  color: #333;
  border: 1px solid #007bff;
  width: 140px;
}

.register-btn {
  background-color: navy;
  color: white;
}

.login-btn:hover, .register-btn:hover {
  background-color: #0056b3;
  color: white;
}

.container {
  flex-wrap: wrap;
  gap: 16px;
  display: flex;
}

.signup-form, .login-form-container {
  position: absolute;
  top: 87px; /* Adjust as needed */
  right: 30px; /* Adjust as needed */
  z-index: 999; /* Ensure the form appears above other content */
  /* Additional styles for the form */
}

.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  background-image: url('../../asset/navbar/african_doctor_images.svg');
  background-size: cover;
  background-position: center;
  color: white;
  background-color: navy;
  /* width: 100%; */
  min-height: 400px;
  position: relative;
  flex-wrap: wrap;
 
}

.hero-content {
  max-width: 50%;
}

.hero-content h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 18px;
  margin-bottom: 30px;
}

.hero-content .register-btn {
  background-color: #f04a42;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.hero-image {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}

.bot-image {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-35%);
  z-index: 1; 
}

.bot-image img {
  width: 180px; 
  height: auto;
}

.desktop-image {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(12.5%);
  z-index: 1; 
}

.desktop-image img {
  width: 600px;
  height: auto;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .hero-content {
      max-width: 100%;
  }
}

@media (max-width: 768px) {
  .navbar-links {
      font-size: 16px;
  }
  
  .hero-content h1 {
      font-size: 36px;
  }

  .hero-content p {
      font-size: 16px;
  }
}

@media (max-width: 480px) {
  .navbar-links {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
  }
  
  .navbar-buttons {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
  }

  .hero-content h1 {
      font-size: 28px;
  }

  .hero-content p {
      font-size: 14px;
  }

  .hero-content .register-btn {
      font-size: 14px;
      padding: 8px 16px;
  }
}
