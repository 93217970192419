/* SuggestionBox.css */

.suggestion-box {
    margin-bottom: 20px;
  }
  
  .show-hide-button {
    background-color: #3498db;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .show-hide-button:hover {
    background-color: #2980b9;
  }
  
  .suggestion-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .suggestion-list.show {
    max-height: 200px; 
    overflow-y: auto;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
  }
  
  .suggestion-item:hover {
    background-color: #f1f1f1;
  }
  
  .no-data-message {
    text-align: center;
    padding: 10px;
    background-color: #f0f0f0;
    color: #333;
    font-weight: bold;
  }