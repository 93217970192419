.model-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 488px;
  height: 451px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  .model-card-wrapper {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .model-card-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        font-size: 36px;
        font-weight: 500;
        line-height: 49.03px;
        color: #262626;
        margin: 0;
        padding: 0;
      }
      p {
        margin: 20px 0 0 0;
        padding: 0;
        font-size: 20px;
        font-weight: 400;
        line-height: 27.24px;
        color: #808080;
      }
    }
    .model-card-bottom {
      display: flex;
      justify-content: space-between;
      .locum-job {
        width: 136px;
        height: 205.31px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
        img {
          width: 100%;
          flex: 6;
          background-color: #f2f6ff;
          object-fit: cover;
        }
        .locum-button {
          flex: 1;
          width: 100%;
          background-color: #2d5fa1;
          border: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 15.69px;
          line-height: 21.37px;
          font-weight: 600;
          button {
            cursor: pointer;
            background-color: transparent;
            outline: none;
            border: none;
            font-size: 15.69px;
            line-height: 21.37px;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
      .permanent-job {
        width: 136px;
        height: 205.31px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
        img {
          width: 100%;
          flex: 6;
          background-color: #f2f6ff;
          object-fit: cover;
        }
        .permanent-button {
          flex: 1;
          width: 100%;
          background-color: #2d5fa1;
          font-size: 15.69px;
          line-height: 21.37px;
          font-weight: 600;
          color: #ffffff;
          border: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            cursor: pointer;
            background-color: transparent;
            outline: none;
            border: none;
            font-size: 15.69px;
            line-height: 21.37px;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
  }
}
