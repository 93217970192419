.b-closePoppup{
  width: 80px;
    height: 36px;
    border-radius: 8px;
    background-color: #00468a;
    color: #fcfcfc;
    font-weight: 600;
    line-height: 24.85px;
    font-size: 18.06px;
    border: 1px solid #fcfcfc;
    outline: none;
    cursor: pointer;
}